
//  INFO:  create new compaigns, each step

import React, { useState } from 'react'
import Modal from '../../components/common/ModalFooter'
import firebase from 'firebase'
import { db } from '../../Firebase'
import { useEffect } from 'react'


const CreateCompaignModal = ({setCreated} : any)  => {
  const [showModal, setShowModal] =useState<boolean>(false)
  const [activeStep, setStep] = useState<number>(1)
  const [isCreating, setCreating] = useState<boolean>(false)
  const [selected, setSelected] = useState<undefined | number>(undefined)
  const [compaignData, setCompaignData] = useState<{
    title : string,
    type : string,
    description : string
  }>({
    title : "",
    type : "image",
    description : ""
  })
  useEffect(() => {
    return () => {
      setCompaignData({
        title : "",
        type : "image",
        description : ""
      })
    }
  },[])

  //  INFO:  next step
  const nextBtn = () => {

  if(activeStep === 1 && compaignData.title.length < 1 ){
    alert("Title cannot be empty ")
    return
  }
  if(activeStep === 2 && compaignData.description.length < 1 ){
    alert("Description cannot be empty")
    return
  }
  if(activeStep === 4) {
    // do the create process
    // console.log(compaignData)
    setCreating(true)
    db.collection("compaigns").add({
      ...compaignData,
      status : "draft",
      scenes : [],
      dateCreated : firebase.firestore.FieldValue.serverTimestamp()
    })
    .then(_ => {
      setStep(1)
      setShowModal(false)
      setCreated(true)
    })


    return
  }
  setStep(activeStep + 1);

}
const backBtn = () => {
  if(activeStep === 1) {
    setStep(1)
    setShowModal(false)
    return
  }
  setStep(activeStep - 1)
}





    return(
      <>
      <button onClick={() => setShowModal(true)} className="btn-primary mr-16">Create New Compaign</button>
      <Modal
      title={activeStep === 1 ? "Enter Compaign Name" 
        :
        activeStep === 2 ? "Enter Description"
        :
        activeStep === 3 ? "Select Compaign Type"
        :
        "Start Compaign"
        
      }
      footer={
        <>
          <button className="text-blue-600 hover:text-blue-800 text-lg font-bold px-8 py-2 mx-1" onClick={backBtn} >{activeStep === 1 ? "Cancel" : "Back"} </button>
          <input type="button" className="bg-blue-500 text-lg text-white font-bold px-8 py-2 mx-1 hover:bg-blue-600" onKeyUp={(e) => {
          }} onClick={nextBtn} value={activeStep === 4 ? `${isCreating ? "Please wait..." : "Start Compaign"}` : "Next" }  />
        </>
        }
        modalCss="shadow-2xl"
        showModal={showModal}
        setShowModal={setShowModal}
      >
        {
        activeStep === 2 ? <Step2 compaignData={compaignData} setCompaignData={setCompaignData} /> : activeStep === 3 ? <Step3 /> : <Step1 compaignData={compaignData} setCompaignData={setCompaignData} />
      }
      </Modal>
      </>
    )
  }





  // STEP 1
  const Step1 = ({compaignData, setCompaignData} : any) => {
    return (
    <div className="w-full flex justify-center justify-items-center bg-gray-900">
      <div className="flex flex-col text-center w-1/2 my-10">
        <span className="my-3 text-2xl font-extralight text-white">Here we are ready to start your new compaign. Our Engines are already
          roaring. Be first give your compaign a name</span>                                
          <input value={compaignData.title} 
          onChange={(e) => setCompaignData({
            ...compaignData,
            title : e.target.value
          })} type="email" placeholder="Enter Compaign Name" 
          className="mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:rounded-xl focus:bg-blue-100 focus:ring-0 px-5" 
          autoFocus
          />
  
      </div>
    </div>
    )
    }

    const Step2 = ({compaignData, setCompaignData} : any) => {
      return (
      <div className="w-full flex justify-center justify-items-center bg-gray-900">
        <div className="flex flex-col text-center w-1/2 my-10">
          <span className="my-3 text-2xl font-extralight text-white">Enter Some nice Description about your compaign</span>                                
            <input value={compaignData.description} onChange={(e) => setCompaignData({
              ...compaignData,
              description : e.target.value
            })} type="textarea" placeholder="Enter Compaign Description" 
            className="mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:rounded-xl focus:bg-blue-100 focus:ring-0 px-5"
            autoFocus
            />
    
        </div>
      </div>
      )
      }


  // const _Step3 = ({selected, setSelected} : any ) => {
  //   return (
  //     <div className="w-full flex justify-center cursor-pointer">
  //       <div className="flex flex-col w-3/5 my-10">
  //         <Card isAvailable Image={iconImage} title="Image" selected={selected === 1 ? true : false} onClick={() => setSelected(1)} description="Choose any image. Upload it and wait while the we tranforms it in your triggers." />
  //         <Card Image={Icon3D} title="Marker" selected={selected === 2 ? true : false} onClick={() => {}} description="Markers are geomatric patterns. Upload any marker of your choice and get ready for the next step." />
  //       </div>
  //     </div>
  //     )
  //   }
  // STEP 2
  const Step3 = () => {
      return (
      <div className="w-full flex justify-center justify-items-center">
        <div className="flex flex-col text-center w-1/2 my-10">
          <h1 className="text-3xl font-bold">Step 3</h1>
          <span className="my-3 text-xl text-justify">Here we are ready to start your new compaign. Our Engines are already
            roaring. Be first give your compaign a name</span>
        </div>
      </div>
      )
    }
  
  
  // STEP 3
  // const Card = ({Image ,title, description, onClick, selected, isAvailable} : any) => {
  
  //   return (
  //     <div onClick={onClick} className={`flex flex-col my-3 rounded-lg p-4 w-full ${selected ? "bg-gray-300" : "bg-gray-100"} ${!isAvailable ? "cursor-not-allowed" : "hover:bg-gray-300"}`}>
  //       {!isAvailable&&<div className="text-red-600 text-xl font-sans font-bold">Soon</div>}
  //       <img className="w-20 h-20" src={Image} alt={""} />
  //       <h2>{title}</h2>
  
  //       <span className="text-mg tracking-widest text-gray-800">
  //         {description}
  //       </span>
  //     </div>
  //   )
  // }
  

  export default CreateCompaignModal