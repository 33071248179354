import React, {useRef} from 'react';
import { setCurrentMeshData } from 'src/state/Actions/uiActions';
import { setSceneProperties } from 'src/state/Actions/sceneActions';
import { updateObject } from '../helpers/scaneReducerHelpers';


const LightObjects = ({dispatch, state}) => {
    const { lightObjects } = state.sceneReducer
    // const ref = useRef()

    const setMeshData = (name) => {
        dispatch(setCurrentMeshData({
            objectType : "light",
            name
        }))
    }

    return (
        <group>
            {
                lightObjects.map(object => {
                const {type, name, id, rotation, scale, position, isVisible} = object
                return (
                <mesh
                    onClick={() => {setMeshData(name)}}
                    scale={[scale.x, scale.y, scale.z]}
                    rotation={[rotation.x,rotation.y, rotation.z]}
                    position={[position.x, position.y, position.z]}
                    name={name}
                    key={id}
                    visible={isVisible}
                    // id={object.id}
                    onPointerLeave={(update) => {
                        const { position : p, rotation : r, scale : s } = update.eventObject
                        const data = {
                            position : {
                                x : p.x,
                                y : p.y,
                                z : p.z
                            },
                            rotation : {
                                x : r.x,
                                y : r.y,
                                z : r.z
                            },
                            scale : {
                                x : s.x,
                                y : s.y,
                                z : s.z
                            }
                        }
                        dispatch(setSceneProperties(updateObject(state.sceneReducer, 'light', object.id, data)))
                    }}
                        userData={{objectType : "light"}}
                    >
                        {
                            type === "point light"?
                                <PointLight object={object} /> : 
                            type === "hemisphere light" ?
                                <HemiSphereLight object={object} />
                            :
                            type === "ambient light" ?
                                <AmbientLight />
                            :type === "spot light" ?
                                <SpotLight />
                            :null
                        }
                
                </mesh>
                )})
            }
        </group>
    )
    ;
};

const HemiSphereLight = ({object}) => {
    const ref = useRef()
    // useHelper(ref, THREE.HemisphereLightHelper, 'red')

return(
    <mesh>
        <mesh 
            position={[-0.025549295363259727,1.1070665211295074,-0.4081428852607099]}
            rotation={[1.1321267433949933,0.03254050614072837,0.17405828077229882]}
            scale={[1.6314801786816893,1.6973406623197,3.146393284468809]}
            //name='Test2'
            >
            <planeGeometry args={[1,1,1]} />
            <meshStandardMaterial color={'green'} opacity={0.1} />
        </mesh>
        <hemisphereLight ref={ref} args={[0xffffbb, 0x080820, 1 ]}/>
    </mesh> 
)
}


const DirectionalLight = ({object}) => {
    const ref = useRef()
    // useHelper(ref, THREE.DirectionalLightHelper, 'blue')

return(
    <mesh>
        {/* <mesh 
            position={[-0.025549295363259727,1.1070665211295074,-0.4081428852607099]}
            rotation={[1.1321267433949933,0.03254050614072837,0.17405828077229882]}
            scale={[1.6314801786816893,1.6973406623197,3.146393284468809]}
            //name='Test2'
            >
            <planeGeometry args={[1,1,1]} />
            <meshStandardMaterial color={'green'} opacity={0.1} />
        </mesh> */}
        <directionalLight ref={ref} args={[0xdedede, 0.7]}/>
    </mesh> 
)
}


const PointLight = ({object}) => {
    const ref = useRef()
    // useHelper(ref, THREE.PointLightHelper, 'red')
return(
    <mesh>
        <mesh 
            position={[-0.025549295363259727,1.1070665211295074,-0.4081428852607099]}
            rotation={[1.1321267433949933,0.03254050614072837,0.17405828077229882]}
            scale={[1.6314801786816893,1.6973406623197,3.146393284468809]}
            //name='Test2'
            >
            <planeGeometry args={[1,1,1]} />
            <meshStandardMaterial color={'green'} opacity={0.1} />
        </mesh>
        <pointLight ref={ref} args={[0x2cff, 0.7]}/>
    </mesh> 
)
}

const SpotLight = ({object}) => {

    return(
        <mesh>
            <mesh 
            position={[-0.025549295363259727,1.1070665211295074,-0.4081428852607099]}
            rotation={[1.1321267433949933,0.03254050614072837,0.17405828077229882]}
            scale={[1.6314801786816893,1.6973406623197,3.146393284468809]}
            //name='Test2'
            >
            <planeGeometry args={[1,1,1]} />
            <meshStandardMaterial color={'green'} opacity={0.1} />
        </mesh>
            <spotLight args={[0x22c55e, 1]} />
        </mesh>
    )
}

const AmbientLight = ({object}) => {
    return(
        <mesh>
            <mesh 
            position={[-0.025549295363259727,1.1070665211295074,-0.4081428852607099]}
            rotation={[1.1321267433949933,0.03254050614072837,0.17405828077229882]}
            scale={[1.6314801786816893,1.6973406623197,3.146393284468809]}
            //name='Test2'
            >
            <planeGeometry args={[1,1,1]} />
            <meshStandardMaterial color={0xdededede} opacity={0.1} />
        </mesh>
            <ambientLight args={[0x404040]} />
        </mesh>
    )
} 


export default LightObjects;
