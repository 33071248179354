import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { auth } from '../../Firebase'
import profile_image from '../../assets/images/profile.png'


const Menu = ({fixed, landing} : any) => {

    const [subMenu, setSubMenu] = useState<boolean>(true)
    const [mobileNav, setMobileNav] = useState<boolean>(true)
    const { is_logged_in } = useSelector((state : any) => state.authReducer)
    const history = useHistory()
    useEffect(() => {
        if(window.innerWidth < 768)
        setMobileNav(false)
    },[])
return(
        <nav
            className={`
            w-full flex lg:px-8 py-4 text-black 
            items-center  justify-between md:justify-start
            ${landing ? "bg-gray-100 relative":"shadow-md bg-white fixed top-0 z-50"}
            `}>
            <div className="flex text-xl font-bold tracking-wider space-x-0 md:mx-1">
                    <Link to="/">Augle.io</Link>
            </div>
            <div className="ml-auto mr-16 justify-around flex space-x-24 items-center">
                <ul
                    className={` 
                    flex md:space-x-2 p-2 md:px-0 md:space-y-0 space-y-1 md:ml-4 md:relative absolute top-full 
                    lg:px-10 flex-col md:flex-row left-0 right-0
                    z-20 
                    -mr-24
                    ${!landing && "bg-white"}
                    ${!mobileNav && "hidden bg-white"}
                    `}>
                    {is_logged_in &&<li>
                        <Link to="/compaigns"
                            className="btn-menu">Compaigns</Link>
                    </li>}
                    {/* <li>
                        <Link to="/"
                            className="btn-menu">Reports</Link>
                    </li> */}
                    {!is_logged_in && <li>
                        <Link to="/login"
                            className="btn-menu">Login</Link>
                    </li>
                    }
                    {/* <li>
                        <Link to="/editor" className="btn-menu bg-black text-white font-sans">Get Started</Link>
                    </li> */}
                </ul>

            {is_logged_in &&   <div className="flex items-center relative space-y-1">
                    <button className="rounded-full overflow-hidden focus:ring-2 focus:ring-white focus:outline-none"
                        onClick={() => {
                            if(window.innerWidth < 768)
                            setMobileNav(false)
                            setSubMenu(!subMenu)
                        }}
                    >
                        <img className="w-10 h-8"
                            src={profile_image} alt="" />
                    </button>
                    <MenuIcon onClick={() => {
                        setSubMenu(true)
                        setMobileNav(!mobileNav)}} />
                        <ul
                        className={`
                        absolute rounded-lg shadow-xl overflow-hidden top-full right-0 bg-white 
                        text-gray-700 w-48 flex flex-col
                        ${subMenu && "hidden"}
                        z-20
                        `}>
                        <li>
                            <Link to="/" className="w-full block px-4 py-2 hover:bg-gray-100">Profile</Link>
                        </li>
                        {/* <li>
                            <Link to="/" className="w-full block px-4 py-2 hover:bg-gray-100">Settings</Link>
                        </li> */}
                        <li>
                            <span onClick={() => {
                                auth.signOut()
                                history.push({
                                    pathname : "/"
                                })
                            }} className="w-full block px-4 py-2 hover:bg-gray-100 cursor-pointer">Log out</span>
                        </li>
                    </ul>
                </div>
                }
            </div>
        </nav>
)}

const MenuIcon = ({onClick} : any) => (
    <div className="flex md:hidden mx-5" onClick={onClick}>
        <button type="button" className="text-purple-800 hover:text-purple-900 focus:outline-none focus:text-purple-800"
            aria-label="toggle menu">
            <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                <path fillRule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
                </path>
            </svg>
        </button>
    </div>
    )
export default Menu