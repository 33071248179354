export const SET_SCENE_PROPERTIES = "SET_SCENE_PROPERTIES"
export const SET_MODEL3D_OBJECTS= "SET_MODEL3D_OBJECTS"
export const SET_VIDEO_OBJECTS = "SET_VIDEO_OBJECTS"
export const SET_TEXT_OBJECTS = "SET_TEXT_OBJECTS"
export const SET_PHOTO_OBJECTS = "SET_PHOTO_OBJECTS"
export const SET_PRIMITIVE_OBJECTS = "SET_PRIMITIVE_OBJECTS"
export const SET_LIGHT_OBJECTS = "SET_LIGHT_OBJECTS"

export const UPDATE_PRIMITIVE_OBJECTS = "UPDATE_PRIMITIVE_OBJECTS"
export const UPDATE_3D_OBJECTS = "UPDATE_3D_OBJECTS"
export const UPDATE_VIDEO_OBJECTS = "UPDATE_VIDEO_OBJECTS"
export const UPDATE_PHOTO_OBJECTS = "UPDATE_PHOTO_OBJECTS"
export const UPDATE_TEXT_OBJECTS = "UPDATE_TEXT_OBJECTS"
export const UPDATE_LIGHT_OBJECTS = "UPDATE_LIGHT_OBJECTS"  
export const DISPOSE_SCENE_STATE = "DISPOSE_SCENE_STATE"