import React from 'react'
import One from '../../assets/svg/One.svg'
import Two from '../../assets/svg/Two.svg'
import { Link } from 'react-router-dom'
import Menu from './Menu'



const index : React.FC = () => {
return (
<main className="bg-gray-100 font-montserrat">
    <Menu landing/>
    <div className="container mx-auto px-6 sm:px-12 flex flex-col-reverse sm:flex-row relative">
        <div className="sm:w-6/12 relative z-10">
            <img src={One} alt="one" />
            </div>
        <div className="sm:w-5/12 xl:w-4/12 flex flex-col items-start sm:items-end sm:text-right ml-auto mt-16 sm:mt-0 relative z-10 xl:pt-20 mb-12 sm:mb-0">
            <h1 className="text-4xl lg:text-5xl text-gray-900 leading-none mb-4 font-custom text-left my-12 tracking-widest">Manage Compaigns Using WebAR</h1>
            <p className="lg:text-xl mb-4 sm:mb-12 text-gray-500 font-extralight">Lorem ipsum dolor sit amet, consectetur adipiscing adipiselit. Pellentesque suscipit tellus vitae purus dignissim, nec tincidunt neque condimentum. Nam nec ligula pretium mi interdum hendrerit in sit.</p>
            <Link to="/compaigns" className="btn-primary hover:text-white">Get Started</Link>
        </div>
            <div className="relative z-10">
                <img src={Two} alt="two" />       
            </div>
    </div>
</main>
)
}


export default index
