import React from 'react';
import augle_icon from 'src/assets/images/icon.png'
import { FaSave } from 'react-icons/fa'
import { ImCheckmark2 } from 'react-icons/im'
import { Link } from 'react-router-dom';
import { db, storage } from 'src/Firebase';
import { useSelector } from 'react-redux';

const Loader = ({content = null,show = false, setShow = () => {}, setContent = () => {}} : {content : null | String, setShow : Function, show : boolean, setContent : Function}) => {
    const handleClose = () => {
        if(content){
            setShow(false)
            setContent("")
        }
    }
return show ? (
<div onClick={handleClose} className={`loader-container`}>
    {content ? <div className='text-2xl text-green-400 flex-col-center'>
        <ImCheckmark2 size={160} />
        <span>
        {content}
        </span>
        </div> : <span className='loader'></span>}
</div>) : null

}


const Menu : React.FC = () => {
    const [showLoader, setLoader] = React.useState(false)
    const [content, setContent] = React.useState<String>("")
    const sceneReducer = useSelector((s : any) => s.sceneReducer)
    const { data_url } = useSelector((s : any) => s.uiReducer)

    
    const handleSaveScene = async() => {
        setLoader(true)
        const screenshot = localStorage.getItem("screenshot")
        const blob : any = screenshot ? await fetch(screenshot).then(b => b.blob()) : null
        db.collection("scenes").doc(sceneReducer?.id).set({...sceneReducer},{merge : true})
        .then(async _ => {
            storage.ref().child(`scenes/${sceneReducer?.id}/screenshot.png`).put(blob)
            .then(_ => {
                console.log("image uploaded success")
            })
            .catch(e => console.log("Error => ", e))
            setContent("Changes are saved successfully!")
        })
    }
    return <div className='h-16 bg-indigo-400 w-full text-center text-white font-extrabold flex flex-row justify-between items-center px-4'>
        <Loader content={content} show={showLoader} setShow={setLoader} setContent={setContent} />
        <Link to="/">
            <div className='flex flex-row items-center'>
                <img alt='' src={augle_icon} width={65} height={65} /> 
                <h2 className='text-white text-3xl font-extrabold'>Augle.io</h2>
            </div>
        </Link>
        <div onClick={handleSaveScene} className=' border-2 rounded-lg p-2 flex-row-center items-center cursor-pointer text-white bg-indigo-500 hover:shadow-2xl' >
            <FaSave size={20} /><span className='ml-2'>Save</span>
        </div>
    </div>;
};

export default Menu;

export {Menu}


