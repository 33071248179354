import React from 'react'

const Loader : React.FC = () => {
    return (
        <span className="flex h-12 w-full flex-col-center">
            <span className="animate-ping absolute inline-flex h-8 w-8 rounded-full bg-gray-700 opacity-75 border-8 border-2 border-blue-500"></span>
        </span>
    )
}

export default Loader
