import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter } from "react-router-dom";
import About from './pages/About';
import Home from './pages/Home';
import Login from './pages/Login';
// import Login2 from './pages/Login';
import Compaigns from './pages/Compaigns'
import CreateCompaigns from './pages/Compaigns/CreateCompaign'
import Editor from './pages/Editor'
import SceneView from './pages/Scenes'

// import Signup from './pages/Signup'
import Reports from './pages/Reports'
import Compaign from './pages/Compaigns/CompaignView'
import Landing from './pages/Landing'
import CoffeePage from './pages/Coffee'
import { useSelector, useDispatch } from 'react-redux';
import Loader from './components/common/Loader';
import { auth } from './Firebase';
import { setUiLoading } from './state/Actions/uiActions';
import { setLoginSuccess, setLogout } from './state/Actions/authActions';


const Routes : React.FC = () => {
  const dispatch = useDispatch()
  const { app_loading } = useSelector((state : any) => state.uiReducer)
  const { is_logged_in } = useSelector((state : any) => state.authReducer)

  // **TODO: REMOVE in Production
  useEffect(() => {
    console.log('app_loaading, routes.js   => ', app_loading)
    auth.onAuthStateChanged((user : any) => {
      if(user){
        dispatch(setLoginSuccess(user))
        dispatch(setUiLoading(false))
      }
      else{
        dispatch(setLogout())
        dispatch(setUiLoading(false))
      }
      
    })
    return () => {
      dispatch(setUiLoading(false))
    }
  },[])
return app_loading ? (
<div className="w-full h-screen flex-col-center">
  <Loader />
  <h3 className="mt-16">Please wait.....</h3>
</div>) : (
  <>
    {
    is_logged_in ? 
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/compaigns/create" component={CreateCompaigns} />
        <Route exact path="/compaigns" component={Compaigns} />
        <Route exact path="/compaigns/:id" component={Compaign} />
        <Route exact path="/editor/:id" component={Editor} />
        <Route exact path="/editor" component={Editor} />
        <Route exact path="/scene/:id" component={SceneView} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/landing" component={Landing} />
        <Route exact path="/landing2" component={Landing} />
        <Route exact path="/coffee" component={CoffeePage} />
      </Switch>
    </BrowserRouter>
    :
    <Login />
  }
    </>
  );
}

export default Routes