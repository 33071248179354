import React from 'react';
import { TransformControls } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import _ from 'lodash';
import { setSceneProperties } from 'src/state/Actions/sceneActions';
import { updateObject } from '../helpers/scaneReducerHelpers';
import { setUiReducer } from 'src/state/Actions/uiActions';

const Controls = (props) => {  
    const {  transform_mode, current_mesh_data } = props.state.uiReducer
    const scene = useThree((state) => state)
    
    const mesh = scene.scene.getObjectByName(current_mesh_data.name)
    if(!mesh) return null

    const {position : p, rotation : r, scale : s} = mesh
    const func = _.debounce(() => {
      const data_url = scene.gl.domElement.toDataURL("image/png")
      // const data_url = scene.gl.domElement.toBlob("image/png")
      localStorage.setItem("screenshot", data_url)
      const data = {
        position : {
          x : p.x,
          y : p.y,
          z : p.z
        },
        rotation : {
          x : r.x,
          y : r.y,
          z : r.z
        },
        scale : {
          x : s.x,
          y : s.y,
          z : s.z
        }
      }
      props.dispatch(setSceneProperties(updateObject(props.state.sceneReducer, mesh.userData.objectType, mesh.userData.id, data)))
    }, 250, { 'maxWait': 1200 });

  return <>
    {current_mesh_data.name && <TransformControls 
        mode={transform_mode} //rotate | translate | scale
        object={mesh}
        onObjectChange={func}
        />}
  </>;
};

export default Controls;
