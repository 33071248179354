export const SET_CAMERA_PROPERTIES = "SET_CAMERA_PROPERTIES"
export const SET_OBJECT_PROPERTIES = "SET_OBJECT_PROPERTIES"
export const SET_OBJECT_CAMERA = "SET_OBJECT_CAMERA"
export const SET_OBJECT = "SET_OBJECT"
export const SET_PATTERN = "SET_PATTERN"
export const UPDATE_RUNTIME ="UPDATE_RUNTIME"
export const SET_TEMP_VALS = "SET_TEMP_VALS" 
export const LOAD_EDITOR = "LOAD_EDITOR"
export const PLAY_ANIMATION = "PLAY_ANIMATION"
export const UPDATE_BG = "UPDATE_BG"
export const ENABLE_IMAGE_TRACKING = "ENABLE_IMAGE_TRACKING"
export const UPDATE_GEOLOCATION = "UPDATE_GEOLOCATION"
export const UPDATE_ACTION_SETTINGS = "UPDATE_ACTION_SETTINGS" 
export const UPDATE_TEXT_SETTINGS = "UPDATE_TEXT_SETTINGS"
export const PUBLISH_SCENE = "PUBLISH_SCENE"