import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLightObjects } from 'src/state/Actions/sceneActions';
import { hideModal } from 'src/state/Actions/uiActions';


import { BsSun } from 'react-icons/bs'
import { FiSunrise } from 'react-icons/fi'
import { SiSpotlight } from 'react-icons/si'
import { HiOutlineLightBulb } from 'react-icons/hi'


const LightModal = () => {
  const [selected, setSelected] = React.useState<String>("")
  const dispatch = useDispatch()
  const { lightObjects } = useSelector((state:any)  =>state.sceneReducer)

  const handlePrimitiveSelect = () => {

    const object = {
      id : `obj_${Date.now()}`,
      type :`${selected.toLowerCase()}`,
      name : selected.split(" ")[0]+"_"+ Date.now(),
      caption : selected,
      color : '#fafa',
      vars : {},
      position : {x : 3, y : 4, z : -3},
      rotation : {x : 1, y : 5.5, z : 1},
      scale : {x : 1, y : 2, z : 1},
      isVisible : true,
      objectType : 'light',
      actions : null
    }
    dispatch(setLightObjects([...lightObjects, object]))
    dispatch(hideModal())
  }

  return (
    <div className='px-12'>
    <h2 className='text-3xl text-center font-extrabold my-4'>Select Light Type</h2>
    <div className='flex flex-row flex-wrap w-full justify-between items-center px-5'>
      <Primitive
        setSelected={() => setSelected("Point Light")}
        selected={selected === "Point Light"     ? true : false} size={100} Icon={BsSun} text={"Point Light"} />
      <Primitive
        setSelected={() => setSelected("Hemisphere Light")}
        selected={selected === "Hemisphere Light"  ? true : false} size={100} Icon={FiSunrise} text={"Hemisphere Light"} />
      <Primitive
        setSelected={() => setSelected("Ambient Light")}
        selected={selected === "Ambient Light"    ? true : false} size={100} Icon={HiOutlineLightBulb} text={"Ambient Light"} />
      <Primitive
        setSelected={() => setSelected("Spot Light")}
        selected={selected === "Spot Light"? true : false} size={100} Icon={SiSpotlight} text={"Spot Light"} />
    </div>
    <div className='w-full flex flex-row justify-end cursor-pointer' onClick={handlePrimitiveSelect}>
      <div className='px-5 py-1 my-2 bg-blue-600 hover:bg-blue-500 text-lg font-semibold text-white'>Select</div>
    </div>

  </div>);
};


const Primitive = ({size, Icon, text, selected, setSelected} : {size : number, Icon : any, text : String, selected : boolean, setSelected:any}) => {
  const [hover, setHover] = React.useState(false)
return(
  <div 
      onClick={setSelected}
      onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} 
      className={`mx-2 my-2 flex flex-col items-center cursor-pointer ${hover || selected? "text-blue-500" : "text-black"}`}>
    <Icon size={size} color={hover || selected? "#3b82f6" : "black"} />
    <span className='text-xl my-3 font-extrabold '>{text}</span>
  </div>
)
}


export default LightModal;
