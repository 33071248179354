import React, { useEffect, useState } from 'react'
import Menu from '../../components/common/Menu'
import { useHistory } from 'react-router'
import { Helmet } from 'react-helmet'
import { db, storage } from '../../Firebase'
import Loader from '../../components/common/Loader'
import Footer from '../../components/common/Footer'
// import ThreeView from '../Editor/MediaTab/ThreeView'

const Index: React.FC = (props : any) => {
    const { id } = props.match.params
    const history = useHistory()
    const [isLoading, setLaoding] = useState<boolean>(true)
    const [sceneData, setSceneData] = useState<any>(null)
    const [thumbnail, setThumb] = useState<null | string>(null)
    const [qr_ref, setQr] = useState<null | string>(null)

    useEffect(() => {
        if(!id) return
        db.collection("scenes").doc(id).get()
        .then(snap => {
            const data : any = snap.data()
            setSceneData(data)
            if(data.qr_ref){
                // console.log("Getting qr link")
                storage.ref().child(data.qr_ref)
                .getDownloadURL()
                .then(url => {
                    setQr(url)
                })
            }
            if(data.patternSettings.image_ref){
                // console.log("setting thumnail")
                storage.ref().child(data.patternSettings.image_ref)
                .getDownloadURL()
                .then(url => {
                    setThumb(url)
                })
                .catch(e => {setThumb(null)})
            }
            setLaoding(false)
        })
        .catch(e => setThumb(null))
    },[])

const pushToEditor = () => {
    if(!sceneData)
        return
    history.push({
        pathname: '/editor/' + id,
    });
}
return id ? (
        <div>
            <Helmet>
                <title>Scene</title>
            </Helmet>
            <Menu />
            <div className="min-h-screen">
            {
                !isLoading ?
                <div className="mt-24 w-full flex flex-row h-96 px-28">
                    <div className="w-1/2 shadow-lg border-2 border-black mx-4 flex flex-col px-5 py-5 ">
                        <span className="my-1">{sceneData.title}</span>
                        <span className="my-1">{sceneData?.description}</span>
                        <span className={`"my-1 rounded-2xl text-white text-md 
                            font-black ${sceneData.status === "published" ? "bg-green-500" : "bg-yellow-500"} 
                            w-min px-3 tracking-widest font-sans"`}
                        >{sceneData?.status}</span>
                        {/* img */}
                        { thumbnail? 
                            <img src={thumbnail} className="h-48 w-64 mt-6" alt=""/>
                        :
                        <div className="h-48 w-64 mt-6 bg-gray-200"></div>
                    }
                        <span onClick={pushToEditor} className="btn-primary w-32 self-end">Edit</span>
                    </div>
                    <div className="w-1/2 shadow-lg border-2 border-black mx-4 flex flex-col-center px-5 py-5 ">
                        {/* <div className="h-full w-full bg-purple-700"></div> */}
                        {qr_ref && <img className="w-72 h-72" src={qr_ref} alt="qr not found" />}
                    </div>
                </div>
            :
            <div className="w-full h-96 flex-col-center">
                <Loader/>
            </div>
            }
            </div>
            <Footer />
        </div>
    )
    :
    <>
    <Helmet>
        <title>Invalid URL</title>
    </Helmet>
    <Menu />
    <div className="w-full h-screen flex-col-center">
        <h1>Scene URL is invalid</h1>
    </div>
    </>
}


const Scene = () => {

}


export default Index
