import React, { useRef } from 'react'
import { setCurrentMeshData } from 'src/state/Actions/uiActions';
// import { useThree } from '@react-three/fiber'
import {  FontLoader } from "three";
import font_src from '../../../assets/fonts/font.json'
import { setSceneProperties } from 'src/state/Actions/sceneActions';
import { updateObject } from '../helpers/scaneReducerHelpers';
import Text from './text/TextLoader'


function TextObjects({state, dispatch}) {
  const ref = useRef()
  const  { textObjects } = state.sceneReducer

  // useFrame(({ clock }) => (ref.current.rotation.x = ref.current.rotation.y = ref.current.rotation.z = Math.sin(clock.getElapsedTime()) * 0.3))
  return (
    <group ref={ref}>
      {
          textObjects.map((object, index) => {
          return <Text key={index} hAlign="right" position={[-12, 6.5, 0]} children="THREE" object={object} state={state} dispatch={dispatch} />
        })
        }
      {/* <Text hAlign="right" position={[-12, 6.5, 0]} children="THREE" />
      <Text hAlign="right" position={[-12, 0, 0]} children="TRES" /> */}
    </group>
  )
}
// <Text hAlign="right" position={[-12, 6.5, 0]} children="THREE" />
  function _Text({dispatch, object, state}) {
    const {position, rotation, scale, name, /*id, caption, */ isVisible, src} = object
    const font = new FontLoader().parse(font_src);
    const textOptions = {
      font,
      size: 10,
      height: 1,
      curveSegments: 6,
      // bevelEnabled: true,
      // bevelThickness: 0.03,
      // bevelSize: 0.02,
      // bevelOffset: 0,
      // bevelSegments: 4
};
      const setMeshData = () => {
        // dispatch(setCurrentMesh(name))
        dispatch(setCurrentMeshData({
            objectType : "3D",
            name
        }))}
    
    return (
      <mesh 
        name={object.name}
        onClick={setMeshData}
        visible={isVisible}
        // id={object.id}
        rotation={[rotation.x, rotation.y, rotation.z]}
        position={[position.x, position.y, position.z]}
        scale={[scale.x, scale.y, scale.z]}
        onPointerLeave={(update) => {
          const { position : p, rotation : r, scale : s } = update.eventObject
            const data = {
            position : {
                x : p.x,
                y : p.y,
                z : p.z
            },
            rotation : {
                x : r.x,
                y : r.y,
                z : r.z
            },
            scale : {
                x : s.x,
                y : s.y,
                z : s.z
            }
        }
          dispatch(setSceneProperties(updateObject(state.sceneReducer, 'text', object.id, data)))
      }}
        >
          <textGeometry attach='geometry' args={["AUGLE.IO", textOptions]} />
          <meshStandardMaterial attach='material' />
      </mesh>
    )
  }
  

export default TextObjects