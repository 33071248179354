import { combineReducers } from "redux";
import authReducer from "./authReducer";
import uiReducer from "./uiReducer";
// import editorReducer from './editorReducer'
import sceneReducer from './sceneReducer'

export default combineReducers({
  authReducer,
  uiReducer,
  // editorReducer,
  sceneReducer
});
