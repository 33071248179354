export const actionList = [
    {   id : 0,
        caption : "Select",
        type : null
    },
    {
        id : 1,
        caption : "Open URL on click",
        type : 'open-url',
        vars : {
            linkAddress : ""
        }
    },
    {
        id : 2,
        caption : "Change Transparency on click",
        type : 'change-transparency',
        vars : {
            opacity : 1
        }
    },
    {
        id : 3,
        caption : "Compose SMS on click",
        type : 'compose-sms',
        vars : {
            textMsg : "",
            recieverNo : ""
        }
    },
    {
        id : 4,
        caption : "Send Email on click",
        type : 'send-email',
        vars : {
            mailBody : "",
            email : "",
            title : ""
        }
    },
    {
        id : 5,
        caption : "show/hide on click",
        type : 'change-visibility',
        vars :{}
    },
    {
        id : 6,
        caption : "Rotate object on click",
        type : 'rotate-obj',
        vars : {
            direction : "" //x|y|z
        }
    }, 
    {
        id : 7,
        caption : "Call Telephone Number on click",
        type : 'call-telephone',
        vars : {
            number : ""
        }
    }
]