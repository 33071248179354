export const SET_LOADING = "SET_LOADING"
export const SET_SAVING = "SET_SAVING"
export const CALL_ANIMATION = "CALL_ANIMATION"
export const SHOW_MODAL = "SHOW_MODAL"
export const HIDE_MODAL = "HIDE_MODAL"
export const SET_CURRENT_MESH = "SET_CURRENT_MESH"
export const SET_TRANSFORM_MODE = "SET_TRANSFORM_MODE"
export const SET_CURRENT_MESH_DATA = "SET_CURRENT_MESH_DATA"
export const DELETE_OBJECT = "DELETE_OBJECT"
export const SET_UI_REDUCER = "SET_UI_REDUCER"
export const DISPOSE_UI_REDUCER = "DISPOSE_UI_REDUCER"