import React, { useRef, Suspense } from 'react';
import { setCurrentMeshData } from 'src/state/Actions/uiActions';
import { useHelper } from '@react-three/drei'
import * as THREE from 'three'


const PrimitiveObject = ({dispatch, object}) => {
    // const scene = useThree((state) => state.scene)
    const ref = useRef()
    useHelper(ref, THREE.BoxHelper, 'red')
    const { scale, rotation, position, name, isVisible, vars } = object
    const color = vars.find(v => v.name  === 'color') 
    const setMeshData = (mesh_name) => {
        // dispatch(setCurrentMesh(mesh_name))
        dispatch(setCurrentMeshData({
            name,
            objectType : "primitive",
        }))
    }
    return (
    <mesh 
        name={name}
        scale={[scale.x, scale.y,scale.z]}
        position={[position.x, position.y, position.z]}
        rotation={[rotation.x, rotation.y, rotation.z]}
        onClick={() => setMeshData(name)}
        ref={ref}
        visible={isVisible}
        // id={object.id}
        userData={{objectType : "primitive", id : object.id}}
    >
        {
            object.type === "box" ? 
            <>
                <boxGeometry args={[vars[0].value,vars[1].value,vars[2].value,vars[3].value,vars[4].value,vars[5].value]} />
                <meshStandardMaterial color={color.value} />
            </> 
            : object.type === "circle"?
            <>
                <circleGeometry args={[vars[0].value,vars[1].value,vars[2].value,vars[3].value]} />
                <meshStandardMaterial color={color.value} />
            </>
            : object.type === "cylinder"?
            <>
                <cylinderGeometry args={[vars[0].value,vars[1].value,vars[2].value,vars[3].value,vars[4].value,vars[5].value,vars[6].value,vars[7].value]} />
                <meshStandardMaterial color={color.value} />
            </>
            : object.type === "torus"?
            <>
                <torusGeometry args={[vars[0].value,vars[1].value,vars[2].value,vars[3].value, vars[4].value]} />
                <meshStandardMaterial color={color.value} />
            </>
            : object.type === "cone"?
            <>
                <coneGeometry args={[vars[0].value,vars[1].value,vars[2].value,vars[3].value,vars[4].value,vars[5].value, vars[6].value]} />
                <meshStandardMaterial color={color.value} />
            </>
            : <mesh />
        }
    </mesh>
)
    }



const Wrapper = ({state, dispatch}) => {

    return(
        <Suspense fallback={null}>
            <group>
                {
                    state.sceneReducer.primitiveObjects.map(object => <PrimitiveObject 
                        object={object} key={object.name} dispatch={dispatch}
                        />
                    )
                }
            </group>
        </Suspense>
    )
}

export default Wrapper;
