import React from 'react'
import one from '../../assets/images/icon_360.png'
import two from '../../assets/images/rocket_image.png'
import three from '../../assets/images/icon_images.png'

const Service = () => {
    return (
        <div className="container mx-auto flex flex-wrap my-24 justify-center">
            <h1 className="headline">Keep your marketing up in space</h1>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div className="max-w-sm rounded overflow-hidden shadow-sm py-12 hover:shadow-md cursor-pointer card">
                    <div className="px-6 py-4">
                        <i className="fa fa-cog"></i>
                        <div className="w-full align-center grid justify-items-center">
                            <img src={one} alt="no coding" className="h-24 my-10" />
                        </div>
                        {/* <hr /> */}
                        <h2 className="text-center font-custom">3D Objects </h2>
                        <p className="paragraph text-center font-medium">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores
                            et perferendis eaque.
                        </p>
                    </div>
                </div>
                <div className="max-w-sm rounded overflow-hidden shadow-sm py-12 hover:shadow-md cursor-pointer card">
                    <div className="px-6 py-4">
                        <i className="fa fa-cog"></i>
                        <div className="w-full align-center grid justify-items-center">
                            <img src={two} alt="no coding" className="h-24 my-10" />
                        </div>
                        <h2 className="text-center font-custom">Manage Compaigns</h2>
                        <p className="paragraph text-center font-medium">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores
                            et perferendis eaque.
                        </p>
                    </div>
                </div>
                <div className="max-w-sm rounded overflow-hidden shadow-sm py-12 hover:shadow-md cursor-pointer card">
                    <div className="px-6 py-4">
                        <i className="fa fa-cog"></i>
                        <div className="w-full align-center grid justify-items-center">
                            <img src={three} alt="no coding" className="h-24 my-10" />
                        </div>
                        <h2 className="text-center font-custom">Images</h2>
                        <p className="paragraph text-center font-medium">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores
                            et perferendis eaque.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service
