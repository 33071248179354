import react, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { db, storage } from 'src/Firebase'
import { ImCross } from 'react-icons/im'
import { GrEdit  } from 'react-icons/gr'
import { setSceneProperties } from 'src/state/Actions/sceneActions'
import { Tabs } from './Tabs'
import Loader from 'src/components/common/Loader'

const MarkerControl = () => {
    const { controls, id } = useSelector((state : any) => state.sceneReducer)
    const { controlImgRef } = useSelector((s : any) => s.uiReducer)
    const dispatch = useDispatch()
    const [step, setStep] = useState<number>(1)
    const [loading, setLoading] = useState(false)
    const [pattFile, setPattFile] = useState<{fileName : any,url : any}>({
        fileName : null,
        url : null
        })
    const [imgFile, setImgFile] = useState({
        fileName : "",
        url : ""
        })



const handleSave = async(e : any) => {
    setLoading(true)
    const imageBlob = await fetch(imgFile.url).then(file => file.blob())
    const isetBlob = await fetch(pattFile.url).then(file => file.blob())

    const storage_ref = `scenes/${id}/controls`
    const image_ref = `${storage_ref}/${imgFile.fileName}`
    // const files_ref = `${storage_ref}.${fileNames.isetFile.split('.').slice(-1).pop()}`
    await storage.ref(image_ref).put(imageBlob)
    await storage.ref(`${storage_ref}/control.patt`).put(isetBlob)
    .then(_ => {
        db.collection("scenes").doc(id).set({
            controls : {
                enabled : true,
                ref : image_ref,
                image_name : imgFile.fileName
            }
        },{merge : true})
        .then(_ => {
            dispatch(setSceneProperties({
                controls : {
                    enabled : true,
                    ref : image_ref,
                    image_name : imgFile.fileName
                }
            }))
        })
        .then(_ => setLoading(false))
    })
}


return(
<div className="w-full flex flex-col flex-start items-center border-2 rounded-lg h-96">

    {!loading ?
    <>
    {
    controls.enabled ? 
    <>
        <div className='flex-col-center py-1'>
            <div onClick={() => dispatch(setSceneProperties({
                controls : {enabled : false}
            }))} className=' items-start self-end rounded-full border bg-gray-100 shadow-md mt-8 p-2 cursor-pointer hover:shadow-xl z-20 -mb-5'>
                <GrEdit size={22} />
            </div>
            {controlImgRef && <>
                <img className='w-full mx-5' src={controlImgRef} alt="Not Found" />
                <h3 className='mt-5 font-extrabold text-center'>
                    <a href={controlImgRef} target="__blank">
                    {controls.ref.split('/').slice(-1).pop()}
                    </a>
                    </h3>
            </>
            }
        </div>
    </>
    :
    <>
        {step === 1 ?
        <div className='mt-10 flex-col-center'>
            <h2 className='my-6 font-black'>Upload Files</h2>
            <input accept=".png, .jpeg, .jpg" onChange={(e : any)=> {
            setImgFile({
            fileName : e.target.files[0].name,
            url : window.URL.createObjectURL(e.target.files[0])})
            setStep(2)
            }} type="file" id="upload-image" hidden/>
            <label htmlFor="upload-image" className="btn-primary cursor-pointer">Upload Image</label>
            <h4 className="text-center font-sans font-medium my-4">Accepted files .jpeg, png </h4>
            <h6 className="my-2 font-sans text-sm text-center">Generate Pattern Image <a
                    className="text-sm font-extralight  text-blue-600"
                    href="https://jeromeetienne.github.io/AR.js/three.js/examples/marker-training/examples/generator.html"
                    target="__blank">Here</a></h6>
            <h4 className='my-2 text-center text-sm font-black'>Please Upload Image and patt file here</h4>
        </div> :
        step === 2 ?
        <div className="mt-10 flex-col-center px-4">
            <img className='w-full h-44' src={imgFile.url} alt="Not Found" />
            <h3 className='my-1 font-extrabold text-left w-full'>{imgFile.fileName}</h3>
            <input accept=".patt" onChange={(e : any)=> {
                setPattFile({
                url : window.URL.createObjectURL(e.target.files[0]),
                fileName : e.target.files[0].name
                })
                }} type="file" id="upload-patt" hidden/>
            {
            pattFile.url?
            <>
                <div className='flex justify-between items-center w-full'>
                    {pattFile.fileName}
                    <ImCross onClick={(e : any)=> setPattFile({url : "", fileName :""})} className="
                        hover:text-red-700 cursor-pointer mx-5" size={12} />
                </div>
                <div onClick={handleSave} className='btn-primary my-3'>Save</div>
            </>
            :
            <>
            <label htmlFor="upload-patt" className="btn-primary px-4 py-2 my-3">Select .patt</label>
            <h5 className="my-3 text-center text-sm font-black">Accepted file .patt </h5>
            </>
            }

        </div> : null
    }</>
    }
    </>
    :
    <div className='h-80 flex-col-center'>
        <Loader />
    </div>
    }
</div>)
}

const MarkerSettings = () => {

return (
<div className='w-full flex-col-center'>
    <h2 className='my-2 text-2xl font-extrabold text-center'>Scene Settings</h2>
    {/* <MarkerControl /> */}
    <Tabs SettingsTab={MarkerControl}/>
</div>
)
}



export default MarkerSettings