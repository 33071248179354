import * as types from '../ActionTypes/sceneActionTypes'

const intialState = {
  title: '',
  status: 'draft',
  id: '',
  sceneType: 'image',
  createdBy :"",
  createdAt : '',
  compaignId : '',
  qrRef : '',
  mediaRef : '',
  controls : {
    location : {
      lat: 1.280734,
      lng: 103.839828
    },
    enabled : false,
    ref : null
  },
  photoObjects: [],
  videoObjects: [],
  model3DObjects: [],
  textObjects: [],
  primitiveObjects: [],
  lightObjects: [], 
};





const sceneReducer = (state = intialState, action: {
  type: String,
  payload: any
}) => {
  const { payload } = action
  switch (action.type) {
    case types.SET_SCENE_PROPERTIES:
      return {
        ...state,
        ...payload
      };
    case types.SET_MODEL3D_OBJECTS:
      return {
        ...state,
        model3DObjects : payload
      }
    case types.SET_TEXT_OBJECTS:
      return {
        ...state,
        textObjects: payload
      }
    case types.SET_PRIMITIVE_OBJECTS:
      return {
        ...state,
        primitiveObjects : payload
      }
    case types.SET_LIGHT_OBJECTS:
      return {
        ...state,
        lightObjects : payload
      }
    case types.SET_VIDEO_OBJECTS : 
    return {
      ...state,
      videoObjects : payload
    }
    case types.SET_PHOTO_OBJECTS:
      return{
        ...state,
        photoObjects : payload
      }
    // case types.UPDATE_3D_OBJECTS:
    //   console.log("Update 3D OBJECTS")
    //   const newObjects = state.model3DObjects.map((obj : any )=> obj.id === payload.id ? {...obj, ...payload.data} : obj)
    //   console.log(newObjects)
    //   return {
    //     ...state,
    //     model3DObjects: []
    //   }
    case types.DISPOSE_SCENE_STATE:
      return action.payload
    default:
      return state;
  }
};


export default sceneReducer