import react from 'react'
import { useSelector } from 'react-redux'
import Loader from 'src/components/common/Loader'

const Tabs = ({SettingsTab}) => {
    const [tab, setTab] = react.useState(1)

    return(<>
        <div className='flex flex-row border-2 border-gray-50 rounded-2xl'>
            <div className={`px-5 py-4 ${tab===1 ? 'bg-blue-500 text-white' :'bg-white text-black'}
                rounded-tl-2xl rounded-bl-2xl cursor-pointer`} onClick={()=> setTab(1)}> Preview
            </div>
            <div className={`px-5 py-4 ${tab===2
                ? 'bg-blue-500 text-white' :'bg-white text-black'} cursor-pointer rounded-tr-2xl rounded-br-2xl`} onClick={()=>
                setTab(2)}> Settings</div>
        </div>
        <div className='w-full bg-white border-2 rounded-lg mx-12 my-7 border-gray-400 px-5 py-5'
            style={{minHeight : '70vh'}}>
            {
            tab === 1 ?
                <Preview />
            :
            <SettingsTab />
            }
        </div>
    </>)
}


function Preview(){

    const scene = useSelector(s => s)
    const { title, sceneType, id } = scene.sceneReducer
    const { qrRef } = scene.uiReducer

    return(
        <div className='h-96'>
            <div className='h-64'>
            {
                qrRef ? 
                <img className='h-full w-full mx-2' src={qrRef} alt={title} />
                :
                <Loader />
            }
            </div>
            <div className='my-3 px-2 py-1 w-full mx-2 border-2 rounded-md'>
                <h3 className='my-2 font-extrabold text-xl text-center'>{title}</h3>
                <div className='my-3 px-4 font-extrabold text-xl flex flex-row justify-between'>
                    <span className='cursor-pointer'>Type</span>
                    <span className='text-green-800 font-black text-base'>
                        {
                            sceneType.toUpperCase()
                        }
                    </span>
                </div>
                <h1 className='text-center py-1'>
                <a className='text-blue-600 font-black text-center' href={`https://lens.augle.io/scene?id=${id}`} target="__blank">AugleIO/Lens</a>
                </h1>
            </div>
        </div>
    )
}


export {
    Tabs,
    Preview
}