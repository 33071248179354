import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useLoader } from "@react-three/fiber"
import { TextureLoader } from "three";
import { setCurrentMeshData } from 'src/state/Actions/uiActions';
import { DoubleSide, BoxHelper } from 'three'
import { Html, useHelper } from '@react-three/drei'
import { storage } from 'src/Firebase';


const ImageObjects = ({dispatch, state}) => {
  const { photoObjects } = state.sceneReducer
  return(
    <Suspense fallback={null}>
    <group>
      {
        photoObjects.map((object, index) => {
          return  <Wrapper key={index} dispatch={dispatch} object={object} state={state} />
      })
      }
    </group>
      </Suspense>
  )
}


  function ImageTextureLoad({dispatch , object, state, url}) {
    const {position, rotation, scale, name, /*id, caption, */ isVisible, src} = object
    
    const texture = useLoader(TextureLoader, url)
    const ref = useRef()
    useHelper(ref, BoxHelper, 'red')
    
    const setMeshData = () => {
      // dispatch(setCurrentMesh(name))
      dispatch(setCurrentMeshData({
          objectType : "photo",
          name
      }))
  }

    return (
      <mesh 
        name={object.name}
        onClick={setMeshData}
        visible={isVisible}
        ref={ref}
        rotation={[rotation.x, rotation.y, rotation.z]}
        position={[position.x, position.y, position.z]}
        scale={[scale.x, scale.y, scale.z]}
        userData={{objectType : "photo", id : object.id}}
      >
        <planeGeometry attach="geometry" args={[3, 3]} />
        {texture && <meshBasicMaterial side={DoubleSide} map={texture} />}
      </mesh>
    )
  }
  

  const Wrapper = ({dispatch , object, state}) => {
    const [url, setUrl ] = useState(null)

    useEffect(() => {
      storage.ref().child(object.mediaRef)
      .getDownloadURL()
      .then(u => setUrl(u))
      .catch(_ => null)
      return () => {
        setUrl(null)
      }
    },[])

    return url ? <ImageTextureLoad url={url} object={object} state={state} dispatch={dispatch} /> : <Loader />

  }

  function Loader() {
    // const { progress } = useProgress()
    return <Html center>loading...</Html>
  }

// export {Wrapper}
export default ImageObjects