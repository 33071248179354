import { SET_LIGHT_OBJECTS, SET_MODEL3D_OBJECTS, SET_PHOTO_OBJECTS, SET_PRIMITIVE_OBJECTS, SET_TEXT_OBJECTS, SET_VIDEO_OBJECTS, SET_SCENE_PROPERTIES, UPDATE_3D_OBJECTS, DISPOSE_SCENE_STATE } from "../ActionTypes/sceneActionTypes";

export const setSceneProperties = payload => ({
    type : SET_SCENE_PROPERTIES,
    payload
})
export const setPrimitiveObjects = payload => ({
    type : SET_PRIMITIVE_OBJECTS,
    payload
})

export const setLightObjects = payload => ({
    type : SET_LIGHT_OBJECTS,
    payload
})

export const setVideoObjects = payload => ({
    type : SET_VIDEO_OBJECTS,
    payload
})


export const setPhotoObjects = payload => ({
    type : SET_PHOTO_OBJECTS,
    payload
})

export const update3D_Objects = payload => ({
    type : UPDATE_3D_OBJECTS,
    payload
})

export const setThreeObjects = payload => ({
    type : SET_MODEL3D_OBJECTS,
    payload
})

export const setTextObjects = payload => ({
    type : SET_TEXT_OBJECTS,
    payload
})


export const disposeSceneState = () => ({
    type : DISPOSE_SCENE_STATE,
    payload : {
        title: '',
        status: '',
        id: '',
        sceneType: '',
        createdBy :'',
        createdAt : '',
        compaignId : '',
        qrRef : '',
        photoObjects: [],
        videoObjects: [],
        model3DObjects: [],
        textObjects: [],
        primitiveObjects: [],
        lightObjects: [], 
    }
})