import React, { useState } from 'react'
// import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
// import Menu from '../../components/common/Menu'
import Footer from '../../components/common/Footer'
import firebase from 'firebase/app'
// import { useSelector } from 'react-redux'

const LoginForm = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    // const [rememberMe, setRememberMe] = useState(false)
    // const history = useHistory()
    // const { is_logged_in } = useSelector((state : any ) => state.authReducer)

    const handleSubmit = () => {
        // if(!rememberMe){
        //     alert("Remember Me")
        //     return
        // }
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then(data => window.location.href = "/"
        //     history.push({
        //     pathname : "/compaigns"
        // })
)
        .catch(e  => alert(e.message))
    }
    
return(
  <div>
      <Helmet>
          <title>Login</title>
      </Helmet>
    {/* <Menu landing /> */}
      <div className="font-sans">
            <div className="relative min-h-screen flex flex-col sm:justify-center items-center bg-gray-100 ">
                <div className="relative sm:max-w-sm w-full">
                    <div className="card bg-gray-700 shadow-lg  w-full h-full rounded-3xl absolute  transform -rotate-6"></div>
                    <div className="card bg-blue-500 shadow-lg  w-full h-full rounded-3xl absolute  transform rotate-6"></div>
                    <div className="relative w-full rounded-3xl  px-6 py-4 bg-gray-100 shadow-md">
                        <label htmlFor="" className="block mt-3 text-3xl text-gray-700 text-center font-semibold">
                            Login
                        </label>
                        <form className="mt-10" onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                            }}>                     
                            <div>
                                <input onChange={(e) => setEmail(e.target.value)} name="email" type="email" placeholder="Enter Email" className="mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0 px-5" />
                            </div>
                
                            <div className="mt-7">                
                                <input onChange={(e) => setPassword(e.target.value)} type="password" name="password" placeholder="Password" className="mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0 px-5" />                           
                            </div>
                            <div className="mt-7 flex">
                                {/* <label htmlFor="remember_me" className="inline-flex items-center w-full cursor-pointer">
                                    <input type="checkbox" value={`${rememberMe}`} onChange={e => setRememberMe(Boolean(!rememberMe))} className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                                    <span className="ml-2 text-sm text-gray-600">
                                        Remeber Me
                                    </span>
                                </label> */}
                                {/* <div className="w-full text-right">     
                                    <Link to="#" className="underline text-sm text-gray-600 hover:text-gray-900">
                                        Forgot Password?
                                    </Link>                                  
                                </div> */}
                            </div>
                            <div className="mt-7">
                                {/* <button onClick={handleSubmit} className="bg-blue-500 w-full py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
                                    Login
                                </button> */}
                                <input type="submit"  value="Login"  className="bg-blue-500 w-full py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105" />
                            </div>
                            <div className="flex mt-7 items-center text-center">
                                <hr className="border-gray-300 border-1 w-full rounded-md" />
                                <label className="block font-medium text-sm text-gray-600 w-full">
                                    OR
                                </label>
                                <hr className="border-gray-300 border-1 w-full rounded-md" />
                            </div>
                            <div className="flex mt-7 justify-center w-full">
                                {/* <button className="mr-5 bg-blue-500 border-none px-4 py-2 rounded-xl cursor-pointer text-white shadow-xl hover:shadow-inner transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
                                    
                                    Facebook
                                </button> */}
                                {/* <button className="bg-red-500 border-none px-4 py-2 rounded-xl cursor-pointer text-white shadow-xl hover:shadow-inner transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">      
                                    Google
                                </button> */}
                            </div>
                
                            <div className="mt-7">
                            <div className="flex justify-center items-center">
                                <label className="mr-2" >New to WebAR?</label>
                                <span onClick={() => alert("Not available")} className="cursor-pointer text-blue-500 transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
                                    Create account
                                </span>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
        <Footer />
  </div>
  )}

export default LoginForm