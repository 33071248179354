import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const PhotoSettings = () => {
    const { uiReducer, sceneReducer } = useSelector((state) => state)
    const { name } = uiReducer.current_mesh_data
    const { primitiveObjects, model3DObjects } = sceneReducer

    const dispatch = useDispatch()
    // var currentObject
    const currentObject = model3DObjects.find(obj => obj.name === name)
    
  return (
    <div className='mt-12'>
        <h1 className='my-2 text-black text-base text-center'>Update Image</h1>
        <button className='btn-primary'>Change</button>
    </div>
  )
}

export default PhotoSettings

