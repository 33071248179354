import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import Loader from 'src/components/common/Loader'
import { db, storage } from 'src/Firebase'
import { GrEdit  } from 'react-icons/gr'
import Loader from 'src/components/common/Loader'
import { setSceneProperties } from 'src/state/Actions/sceneActions'
import { Tabs } from './Tabs'

const ImageTrackingSettings = () => {
    const [imageUrl, setImageUrl] = useState<null | any>(null)
    const [isetUrl, setIsetUrl] = useState<null | any>(null)
    const [fsetUrl, setFsetUrl] = useState<null | any>(null)
    const [fset3Url, setFset3Url] = useState<null | any>(null)
    const [step, setStep] = useState<number>(1)
    const [loading, setLoading] = useState(false)
    const [fileNames, setFilesNames] = useState({
        imageFile : "",
        isetFile : "",
        fsetFile : "",
        fset3File : ""
    })

    const dispatch = useDispatch()
    const { id, controls } = useSelector((state : any) => state.sceneReducer)
    const { controlImgRef } = useSelector((s : any) => s.uiReducer)

    const handleSave = async(e : any) => {
        if(!imageUrl || !isetUrl || !fsetUrl || !fset3Url){
            alert("Please select all the required files")
            return
        }
        setLoading(true)
        const imageBlob = await fetch(imageUrl).then(file => file.blob())
        const isetBlob = await fetch(isetUrl).then(file => file.blob())
        const fsetBlob = await fetch(fsetUrl).then(file => file.blob())
        const fset3Blob = await fetch(fset3Url).then(file => file.blob())
        const storage_ref = `scenes/${id}/controls`
        const image_ref = `${storage_ref}/${fileNames.imageFile}`
        await storage.ref(image_ref).put(imageBlob)
        await storage.ref(`${storage_ref}/control.iset`).put(isetBlob)
        await storage.ref(`${storage_ref}/control.fset`).put(fsetBlob)
        await storage.ref(`${storage_ref}/control.fset3`).put(fset3Blob)
        .then(_ => {
            db.collection("scenes").doc(id).set({
                controls : {
                    enabled : true,
                    ref : image_ref,
                    image_name : fileNames.imageFile
                }
            },{merge : true})
            .then(_ => {
                console.log("Setting state")
                dispatch(setSceneProperties({
                    controls : {
                        enabled : true,
                        ref : image_ref,
                        image_name : fileNames.imageFile
                    }
                }))
                setLoading(false)
            })
        })
    }
    const StepOne = () => {
        return(
            <div className="flex flex-col items-center justify-start">
                            <h1 className="text-center text-lg my-4 font-custom font-bold -mt-20">Image file</h1>
                            <input accept=".png, .jpeg, .jpg" onChange={(e : any) => {
                                setImageUrl(window.URL.createObjectURL(e.target.files[0]))
                                setFilesNames({
                                    ...fileNames,
                                    imageFile : e.target.files[0].name
                                })
                                setStep(2)
                                }} type="file" id="upload-btn" hidden/>
                            {
                            imageUrl ? 
                                <div>
                                    {fileNames.imageFile}
                                        <span onClick={() => setImageUrl(null)} className="font-extrabold text-xl hover:text-red-700 cursor-pointer font-custom mx-4">x</span>
                                </div> 
                                :
                                <label htmlFor="upload-btn" className="btn-primary bg-indigo-500 hover:bg-indigo-400 cursor-pointer">Upload Image</label>
                            }
                            <h4 className="text-center font-sans font-medium my-2">Accepted files  .jpeg, png </h4>
                            <h6 className="my-2 font-sans text-sm text-center">Generate Image Descriptors <a className="text-sm font-extralight  text-blue-600" href="https://carnaux.github.io/NFT-Marker-Creator/#/" target="__blank">Here</a></h6>
                        </div>
        )
    }
    const StepTwo = () => {
        return(
            <div className="flex flex-col items-center justify-start">
                            <h3 className="text-center text-sm font-black ">Upload Image Descriptor Files</h3>
                            <div className="my-2">
                                <input accept=".iset" onChange={(e : any) => {
                                    setIsetUrl(window.URL.createObjectURL(e.target.files[0]))
                                    setFilesNames({
                                        ...fileNames,
                                        isetFile : e.target.files[0].name
                                    })
                                    }} type="file" id="upload-iset" hidden/>
                                {
                                    isetUrl ? 
                                    <div>
                                        {fileNames.isetFile}
                                        <span onClick={() => setIsetUrl(null)} className="font-extrabold text-xl hover:text-red-700 cursor-pointer font-custom mx-4">x</span>
                                    </div> 
                                        :
                                <label htmlFor="upload-iset" className="btn-primary px-4 py-2 my-3">Select .iset</label>
                                }
                            </div>
                            <div className="my-2">
                                <input accept=".fset" onChange={(e : any) => {
                                    setFsetUrl(window.URL.createObjectURL(e.target.files[0]))
                                    setFilesNames({
                                        ...fileNames,
                                        fsetFile : e.target.files[0].name
                                    })
                                    }} type="file" id="upload-fset" hidden/>
                                {
                                    fsetUrl ? 
                                    <div>
                                        {fileNames.fsetFile}
                                        <span onClick={() => setFsetUrl(null)} className="font-extrabold text-xl hover:text-red-700 cursor-pointer font-custom mx-4">x</span>
                                    </div> 
                                        :
                                    <label htmlFor="upload-fset" className="btn-primary px-4 py-2 my-3">Select .fset</label>
                                }
                            </div>
                            <div className="my-2">
                                <input accept=".fset3" onChange={(e : any) => {
                                    setFset3Url(window.URL.createObjectURL(e.target.files[0]))
                                    setFilesNames({
                                        ...fileNames,
                                        fset3File : e.target.files[0].name
                                    })
                                    setStep(3)
                                    }} type="file" id="upload-fset3" hidden/>
                                {fset3Url ? 
                                    <div>
                                        {fileNames.fset3File}
                                        <span onClick={() => setFset3Url(null)} className="font-extrabold text-xl hover:text-red-700 cursor-pointer font-custom mx-4">x</span>
                                    </div> 
                                        :
                                <label htmlFor="upload-fset3" className="btn-primary px-3 py-2 my-3">Select .fset3</label>
                                }    
                            </div>
                        </div>
        )
    }

    // Final Step
    const StepThree = () => {

    return(
        <div className='flex-col-center'>
            <img className='w-full mx-5 -mt-20' src={imageUrl} alt="Not Found" />
            <h3 className='mt-1 font-extrabold text-center'>{fileNames.imageFile}</h3>
            <div onClick={handleSave} className='btn-primary'>Upload</div>
        </div>
    )
}
return (
    <div className="w-full m-0 h-96">
        {
        !loading ?
        <>
            {
            controls.enabled?
                    <div className='flex-col-center py-5'>
                        <div onClick={() => {
                            dispatch(setSceneProperties({
                                controls : {enabled : false}
                            }))
                        }} className=' items-start self-end rounded-full border shadow-md -mt-6 p-2 cursor-pointer hover:shadow-xl z-20 -mb-5'>
                            <GrEdit size={22} />
                        </div>
                        {controlImgRef && <img className='w-full mx-5' src={controlImgRef} alt="Not Found" />}
                        <h3 className='mt-5 font-extrabold text-center'>{controls?.ref.split('/').slice(-1).pop()}</h3>
                    </div>
                :
                <>
                    <div className="flex flex-row w-full mb-6">
                        <h2 onClick={() => null} className={`text-center font-custom font-extrabold w-full  py-6 text-3xl`}>Image Tracking</h2>
                    </div>
                    <div className="flex-col-center items-center h-80 pt-10">
                        {
                            step === 1 ? <StepOne /> :step === 2 ? <StepTwo /> : <StepThree />
                        }  
                    </div>
                </>
            }
            </> 
            :
            <div className='flex-col-center h-96'>
                <Loader />
            </div>}
        </div>
    )
}


const SettingsTabs = () => {

    return (
    <div className='w-full flex-col-center h-96 mt-32'>
        <h2 className='my-2 text-2xl font-extrabold text-center'>Scene Settings</h2>
        {/* <MarkerControl /> */}
        <Tabs SettingsTab={ImageTrackingSettings} />
    </div>
    )
    }
    



export default SettingsTabs
