
//   INFO:  compaign card component

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { db } from '../../Firebase'
import Loader from '../../components/common/Loader'

const CompaignCards = ({type, filter, isCreated} : any) => {
    const [activeCompaigns, setActiveCompaign] = useState<any[]>([])
    const [draftCompaigns, setDraftCompaign] = useState<any[]>([])
    const [isLoading, setLoading] = useState<boolean>(true)
    const currentDate = moment().format("DD-MM-YYYY").split("-");
    useEffect(() => {
        setActiveCompaign([])
        setDraftCompaign([])
        getCompaigns()

        return () => {
            setActiveCompaign([])
            setDraftCompaign([])
        }
    },[filter,isCreated])


    //  INFO: get compaigns from database
    const getCompaigns = () => {
        let ref = db.collection("compaigns").where("status", "==", type)
        if(filter === 'month')
        {
        ref
        .where('dateCreated', '>', new Date(`${currentDate[2]}-${currentDate[1]}-01`))
        .get()
        .then(collections => {
            collections.forEach(snap => {
                const collection = snap.data()
                const obj = {
                    title : collection.title,
                    status : collection.status,
                    dateCreated : collection.dateCreated,
                    scenes : collection.scenes,
                    docId : snap.id
                }
                if(type === "active")
                setActiveCompaign((prev : any ) => [...prev, obj])
                else
                setDraftCompaign((prev : any ) => [...prev, obj])
            })
        })
        .then(_ => setLoading(false))
        }
        else if(filter === "year")
        {
        ref
        //apply filter for getting required compaigns
        .where('dateCreated', '>', new Date(`${currentDate[2]}-01-01`))
        .get()
        .then(collections => {
            collections.forEach(snap => {
                const collection = snap.data()
                const obj = {
                    title : collection.title,
                    status : collection.status,
                    dateCreated : collection.dateCreated,
                    scenes : collection.scenes,
                    docId : snap.id
                }
                if(type === "active")
                setActiveCompaign((prev : any ) => [...prev, obj])
                else
                setDraftCompaign((prev : any ) => [...prev, obj])
            })
        })
        .then(_ => setLoading(false))
        }
        else 
        {
        ref
        .get()
        .then(collections => {
            collections.forEach(snap => {
                const collection = snap.data()
                const obj = {
                    title : collection.title,
                    status : collection.status,
                    dateCreated : collection.dateCreated,
                    scenes : collection.scenes,
                    docId : snap.id
                }
                if(type === "active")
                setActiveCompaign((prev : any ) => [...prev, obj])
                else
                setDraftCompaign((prev : any ) => [...prev, obj])
            })
        })
        .then(_ => setLoading(false))
        .catch(e => console.log("ERROR   " , e))
    }
}
    
return(
    <div className="flex flex-row flex-wrap mx-16 px-10">

        {
            type === "active" ?
            activeCompaigns.map((compaign, index) => {
                const date = compaign.dateCreated.toDate()
                return <Card  title={compaign.title} status={compaign.status} dateCreated={`${moment(date).format("ll")}`} id={compaign.docId} key={index}/>
            })
            :
            draftCompaigns.map((compaign, index) => {
                const date = compaign.dateCreated.toDate()
                return  <Card  title={compaign.title} status={compaign.status} dateCreated={`${moment(date).format("ll")}`} id={compaign.docId} key={index}/>
            })
        }
        {isLoading && <Loader />}
        </div>
)}



//  INFO:  card components
const Card = ({ title, status, dateCreated, id} : any) => {
    const [hover, setHover] = useState<boolean>(false)
    const history = useHistory()
    const navigate = () => {
        history.push({
            pathname: '/compaigns/' + id,
        });
    }
return(
    <div onMouseLeave={() => setHover(false)} onMouseOver={() => setHover(true)} className={` rounded-xl mx-1 px-8 py-3 my-6 cursor-pointer ${hover ? "bg-gray-700" : "bg-gray-100"}`} style={{width : "30%"}} onClick={navigate}>
        <div className="flex flex-col w-4/5 justify-center">
            <span className={
                `my-2 rounded-2xl text-white text-md 
                font-black ${status === 'active' ? "bg-green-500" : "bg-yellow-400"}
                w-min px-3 tracking-widest font-sans
                `}>{status}</span>
            <h4 className={`my-2 text-2xl heading font-extrabold font-custom ${hover ? "text-white" : "text-black"}`}>{title}</h4>
            <span className={`my-2 text-xl font-normal font-sans ${hover ? "text-white" : "text-black"}`}>{dateCreated}</span>
        </div>
    </div>
)}


export default CompaignCards