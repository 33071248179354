import react, {Suspense, useEffect, useRef, useState} from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useLoader } from '@react-three/fiber'
import { setCurrentMeshData } from 'src/state/Actions/uiActions';
import { Html } from '@react-three/drei'
// import { setSceneProperties } from 'src/state/Actions/sceneActions';
// import { updateObject } from '../helpers/scaneReducerHelpers';
import { useHelper } from '@react-three/drei'
import * as THREE from 'three'
import { storage } from 'src/Firebase';

const ThreeObject = ({dispatch, state}) => {
  const { model3DObjects } = state.sceneReducer
  return (
    <group>
    {
      model3DObjects.map((object, index) =><Wrapper key={object.name} object={object} dispatch={dispatch} state={state} />)
    }
    </group>
  );
};


function ThreeLoader({object, dispatch, url}) {
  const gltf = useLoader(GLTFLoader, url)
  const {position, rotation, scale, name, /*id, caption, */} = object
  const ref = useRef()
  useHelper(ref, THREE.BoxHelper, 'red')

    const setMeshData = () => {
      // dispatch(setCurrentMesh(name))
      dispatch(setCurrentMeshData({
          objectType : "3D",
          name
      }))
    }
  // const copiedScene = useMemo(() => scene.clone(), [scene])
  return (
      <mesh
        name={name}
        onClick={setMeshData}
        visible={object.isVisible}
        ref={ref}
        // id={object.id}
        rotation={[rotation.x, rotation.y, rotation.z]}
        position={[position.x, position.y, position.z]}
        scale={[scale.x, scale.y, scale.z]}
        userData={{objectType : "3D", id : object.id}}
      >
        <primitive object={gltf.scene} />
      </mesh>
  )
}


const Wrapper = ({dispatch , object, state}) => {
  const [url, setUrl ] = useState(null)

  useEffect(() => {
    storage.ref().child(object.mediaRef)
    .getDownloadURL()
    .then(u => setUrl(u))
    .catch(_ => null)

    return () => {
      setUrl(null)
    }
  },[])

  return url ?  <Suspense fallback={<mesh />}><ThreeLoader url={url} object={object} state={state} dispatch={dispatch} /></Suspense>
  : <Loader />
}

function Loader() {
  // const { progress } = useProgress()
  return <Html  center>loading...</Html>
}

export default ThreeObject;
