import react from 'react'
import { RiDeleteBack2Line } from 'react-icons/ri'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { setSceneProperties } from 'src/state/Actions/sceneActions'
// import { TiPlus } from 'react-icons/ti'
import { actionList } from '../../helpers/actionsList' 
import { findObject } from '../../helpers/scaneReducerHelpers'

const ActionsTab = () => {
    const [actionNumber, setActionNumber] = react.useState(null)
    const [flag, setFlag] = react.useState(false)

    const {name, objectType} = useSelector(s => s.uiReducer.current_mesh_data)
    // const { sceneReducer } = useSelector(s => s)
    const store = useStore()
    const {sceneReducer} = store.getState()
    const { model3DObjects, photoObjects, videoObjects, primitiveObjects } = sceneReducer
    const dispatch = useDispatch()
    const [ selectedObject, setSelectedObject ] = react.useState(null)//findObject(sceneReducer, objectType, name))
    const [input, setInput] = react.useState(null)

    react.useEffect(() => {
      const findObj =  findObject(sceneReducer, objectType, name)
      
      if(!findObj) return
      setSelectedObject(findObj)
      const temp = {
        openUrl : findObj.actions.map(a => a.id === 1 ? a.vars : {linkAddress : ''}),
        changeTransparency : findObj.actions.map(a => a.id === 2 ? a.vars : {opacity : 1}),
        composeSMS : findObj.actions.map(a => a.id === 3 ? a.vars : {textMsg : '',recieverNo : ''}),
        sendEmail : findObj.actions.map(a => a.id === 4 ? a.vars : {mailBody : '',email : '',title : ''}),
        callTelephone : findObj.actions.map(a => a.id === 7 ? a.vars : {number : ''})
      }
      setInput(temp)
      console.log(temp);
    },[name, flag])

    const addAction = (id) => {
      // console.log(id, ' adding object ', selectedObject);
      const isFound = selectedObject.actions.find(a => a.id === id ? true : false)
      if(isFound || id === 0) return
      
      if(objectType === 'photo'){
        const updateObj = photoObjects.map(obj => obj.name === selectedObject.name ? {...obj, actions :[...obj.actions,actionList[id]]}  : obj)
        dispatch(setSceneProperties({...sceneReducer, photoObjects : updateObj}))
      }
      else if(objectType === 'video'){
        const updateObj = videoObjects.map(obj => obj.name === selectedObject.name ? {...obj, actions :[...obj.actions,actionList[id]]} : obj)
        dispatch(setSceneProperties({...sceneReducer, videoObjects : updateObj}))
      }
      else if(objectType === '3D'){
        const updateObj = model3DObjects.map(obj => obj.name === selectedObject.name ? {...obj, actions :[...obj.actions,actionList[id]]} : obj)
        dispatch(setSceneProperties({...sceneReducer, model3DObjects : updateObj}))
      }else {
        const updateObj = primitiveObjects.map(obj => obj.name === selectedObject.name ? {...obj, actions :[...obj.actions,actionList[id]]}  : obj)
        dispatch(setSceneProperties({...sceneReducer, primitiveObjects : updateObj}))
      }
      setFlag(!flag)
  }


    const removeAction = (id) => {
      if(objectType === 'photo'){
        const updateObj = photoObjects.map(obj => obj.name === name ? {...obj, actions : obj.actions.filter(act => act.id !== id)} : obj)
        dispatch(setSceneProperties({...sceneReducer, photoObjects : updateObj}))
        
      }else if(objectType === 'video'){
        const updateObj = videoObjects.map(obj => obj.name === selectedObject.name ? {...obj, actions : obj.actions.filter(act => act.id !== id)} : obj)
        dispatch(setSceneProperties({...sceneReducer, videoObjects : updateObj}))
      } 
      else if(objectType === '3D'){
        const updateObj = model3DObjects.map(obj => obj.name === selectedObject.name ? {...obj, actions : obj.actions.filter(act => act.id !== id)} : obj)
        dispatch(setSceneProperties({...sceneReducer, model3DObjects : updateObj}))
      }
      else{
        const updateObj = primitiveObjects.map(obj => obj.name === selectedObject.name ? {...obj, actions : obj.actions.filter(act => act.id !== id)} : obj)
        dispatch(setSceneProperties({...sceneReducer, primitiveObjects : updateObj}))
      }
      
      
      setFlag(!flag)
    }

    const handleChange = (e, type) => {
      console.log(type)
      if(type === 1){
        setInput({
          ...input,
          openUrl:{
            ...input.openUrl,
            [e.target.name]: e.target.value},
        })
      }else if(type === 2){
          setInput({
          ...input,
          changeTransparency:{
            ...input.changeTransparency
            ,[e.target.name]: e.target.value},
        })
      }else if(type === 3){
          setInput({
            ...input,
            composeSMS:{
              ...input.composeSMS
              ,[e.target.name]: e.target.value},
          })
      }else if(type === 4){
          setInput({
            ...input,
            sendEmail:{
              ...input.sendEmail
              ,[e.target.name]: e.target.value},
          })
      }
      if(type === 7){
          setInput({
          ...input,
          callTelephone:{
            ...input.callTelephone
            ,[e.target.name]: e.target.value},
        })
      }
      e.target.focus()
    }

    const handleSave = () => {
      const tempObj = {
        ...selectedObject,
        actions : selectedObject.actions.map(a => {
          const {id} = a
          return  id === 1 ? {...a,vars : {linkAddress : input.openUrl.linkAddress}}
          : id === 2 ? {...a, vars : {opacity : input.changeTransparency.opacity}}
          : id === 3 ? {...a, vars : {textMsg : input.composeSMS.textMsg,recieverNo : input.composeSMS.recieverNo}}
          : id === 4 ? {...a, vars : { title : input.sendEmail.title,email : input.sendEmail.email,mailBody : input.sendEmail.mailBody}}
          : id === 5 ? {...a, vars : {number : input.callTelephone.number}}
          : a
        })
      }

      console.log(tempObj)
     
        setActionNumber(null)
    }

    const Action = ({action}) => (
        <div className='w-full flex flex-row justify-between items-center'>
            <div onClick={() => action.id !== 5 && action.id !== 6 && setActionNumber(action.id)} className='w-full bg-gray-300 hover:bg-gray-400 cursor-pointer rounded py-1 px-3 my-1'>{action.caption} </div>
            <div onClick={() => removeAction(action.id)} className='px-3 py-1 bg-red-600 text-white mx-2 my-1 rounded cursor-pointer hover:bg-red-500' > <RiDeleteBack2Line color="white" size={22} /></div>
        </div>
    )

    return(
        <div className='flex-col-center'>
            {/* <h1 className='my-2'>Behaviours</h1>
            <div className='w-full flex flex-row justify-between items-center'>
                <div className='w-full bg-gray-300 rounded py-3 px-5 my-1 text-xl font-bold'>Select Action</div>
                <div className='px-5 py-3 bg-blue-600 text-white mx-2 my-1 rounded cursor-pointer hover:bg-blue-500' > <TiPlus color="white" size={28} /></div>
            </div> */}
            {actionNumber && input ?
            <div>
                <ActionType handleChange={handleChange} actionNumber={actionNumber} input={input} />
                <div className='w-full flex-row-center'>
                  <button type='submit' onClick={handleSave} className='btn-primary my-2 py-1 px-4'>Save</button>
                </div>
            </div>
            :
            <>
              <SelectAction addAction={addAction} />
              <div className='my-3 mx-1 w-full mt-5'>
                {
                  selectedObject?.actions.map(action => <Action key={action.id} action={action} />)
                }
              </div>
            </>
            }
        </div>
    )
}




const SelectAction = ({addAction}) => {
    const [isOpen, setOpen] = react.useState(false)
    return(
    <>
      <div className="relative inline-block text-left">
        <div>
          <button onClick={() => setOpen(true)} type="button" className="inline-flex w-64 justify-between rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-bold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
            Select Action
            <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        <div className={`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${!isOpen && "hidden"} w-64`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
          <div className="py-1" role="none">
            {
              actionList.map((option, index) => <span onClick={() =>{
                addAction(option.id)
                setOpen(false)
              }} key={index} className="text-gray-700 block px-4 py-2 text-sm cursor-pointer">{option.caption}</span>)
            }
            </div>
        </div>
      </div>
    </>
    )
  }
  const ActionType = ({actionNumber, handleChange, input}) => {
    const inputStyle = `form-control block  w-full px-3 py-1 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`
    if(!actionNumber) return
    switch(actionNumber){
      case 1:
        return(<div>
          <h2 className='text-sm mt-2 font-black' placeholder='www.abc.com'>Enter URL </h2>
          <input onChange={(e) =>handleChange(e,1)} name='linkAddress' className={inputStyle} type='text' value={input.openUrl.linkAddress} />
        </div>)
      case 2:
        return(<div>
          <h2 className='text-sm mt-2 font-black'>Enter Transparency value </h2>
          <input onChange={(e) =>{handleChange(e,2)}} name='opacity' type='number' style={inputStyle} value={input.changeTransparency.opacity} />
        </div>)
      case 3:
        return(<div>
          <h2 className='text-sm mt-2 font-black' >Enter Reciever Number </h2>
          <input onChange={(e) =>handleChange(e,3)} className={inputStyle} type='text' name='recieverNo' placeholder='+92 XXX XXXXXX' value={input.composeSMS.recieverNo} />
          <h2 className='text-sm mt-2 font-black'>Enter Message </h2>
          <textarea onChange={(e) =>handleChange(e,3)} className={inputStyle} name='textMsg' placeholder='AR is fun' value={input.composeSMS.textMsg}  />
        </div>) 
      case 4:
        return(<div>
          <h2 className='text-sm mt-2 font-black'>Enter Reciever Email </h2>
          <input onChange={(e) =>handleChange(e,4)} className={inputStyle} type='text' placeholder='abc@example.com' name='email' value={input.sendEmail.email} />
          <h2 className='text-sm mt-2 font-black' placeholder='hello'>Enter Email Subject </h2>
          <input onChange={(e) =>handleChange(e,4)} className={inputStyle} type='text' name='title' value={input.sendEmail.title} />
          <h2 className='text-sm mt-2 font-black' >Enter Email Body </h2>
          <textarea onChange={(e) =>handleChange(e,4)} className={inputStyle} placeholder='this is email body' name='mailBody' value={input.sendEmail.mailBody} />
        </div>)
      case 7:
        return(<div>
          <h2 className='text-sm mt-2 font-black'>Enter Telephone Number </h2>
          <input onChange={(e) =>handleChange(e,7)} type='text' className={inputStyle} placeholder='042 00 453232' name='number' value={input.callTelephone.number} />
        </div>)
      default : 
      return <div></div>
    }
  }
export default ActionsTab