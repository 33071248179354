import { CALL_ANIMATION, DELETE_OBJECT, HIDE_MODAL, SET_CURRENT_MESH_DATA, SET_LOADING, SET_SAVING, SET_TRANSFORM_MODE, SET_UI_REDUCER, SHOW_MODAL } from "../ActionTypes/uiActionTypes";
import { SET_TEMP_VALS } from "../ActionTypes/editorActionTypes"
const intialState = {
  show_modal : false,
  modal_content : "3D Modal",
  current_mesh_data : {
    name : '',
    objectType : ''
  },
  transform_mode : "translate",
  is_loading : false,
  compaign : {
  },
  qrRef : null,
  controlImgRef : null,
  // TODO: app_loading : true, in production
  app_loading : true,
  saving_data : false,
  call_animation : "",
  tempVals : {
    tabOptions : null,
    currentSettings : "media",
    animation : null
  },
  data_url : ""
};

const uiReducer = (state = intialState, action) => {
  const {payload} = action
  switch (action.type) {
    case SET_LOADING:
      return { 
        ...state, 
        app_loading : payload 
      };
    case SET_SAVING:
      return {
        ...state,
        saving_data : payload
      }
    case CALL_ANIMATION:
      return {
        ...state,
        call_animation : payload
      }
      case SET_TEMP_VALS : 
      return{
        ...state,
        tempVals : payload
      }
      case SHOW_MODAL : 
      return {
        ...state,
        show_modal : true,
        modal_content : payload
      }
      case HIDE_MODAL:
        return{
          ...state,
          show_modal : false,
          modal_content : ""
        }
      case SET_CURRENT_MESH_DATA:
        return{
          ...state,
          current_mesh_data : payload
        }
      case SET_TRANSFORM_MODE:
        return{
          ...state,
          transform_mode : payload
        }
      case DELETE_OBJECT:
        return{
          ...state,
          delete_object : payload
        }
      case SET_UI_REDUCER:
        return{
          ...state,
          ...payload
        }
    default:
      return state;
  }
};

export default uiReducer