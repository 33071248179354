import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import configStore from './state/store'
import { Provider } from 'react-redux'
import "./index.css"
import "./App.css"

// import Login from './pages/Login'
// if(!firebase.apps.length)
//   firebase.initializeApp(firebaseConfig);
//   //<React.StrictMode>

ReactDOM.render(
    <Provider store={configStore()}>
      <div className="b-gray-50 min-h-screen">
        <Routes />
      </div>
    </Provider>,
  document.querySelector('#root'),
);
