import * as types from '../ActionTypes/authActionTypes'
const intialState = {
  is_logged_in : false,
  uid: null,
  email : null,
  user_data : {},
};
const authReducer = (state = intialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state, 
        is_logged_in: true,
        user_data : {
          email : action.payload?.email | '',
          uid : action.payload.uid,
          displayName : action.payload.displayName,
          photoURL : action.payload.photoURL,
          refreshToken : action.payload.refreshToken,
        }
      };
    case types.SET_USERDATA:
      return {
        ...state,
        user_data : action.payload
      }
    case types.LOGOUT_USER:
      return {
        ...state,
        is_logged_in : false,
        user_data : {}
      }
    default:
      return state;
  }
};

export default authReducer