import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTextObjects } from 'src/state/Actions/sceneActions';
import { hideModal } from 'src/state/Actions/uiActions';
/* eslint-ignore line */
import font_src from '../../../assets/fonts/font.json'



const TextModal = () => {
  const dispatch = useDispatch()
  const { textObjects } = useSelector((state)  =>state.sceneReducer)
  const handleTextSelect = () => {

    const object = {
      id : `obj_${Date.now()}`,
      type :`text`,
      name : "Text_"+ Date.now(),
      caption : "Text",
      value : "Augle.io",
      vars : [],
      position : {x : 0, y : 0, z : 0},
      rotation : {x : 0, y : 0, z : 0},
      scale : {x : 1, y : 1, z : 1},
      isVisible : true,
      actions : [],
      objectType : 'text'
    }
    dispatch(setTextObjects([...textObjects, object]))
    dispatch(hideModal())
  }

  return (
    <div className='px-12'>
    <h2 className='text-3xl text-center font-extrabold my-4'>Add Text</h2>
    <div className='flex flex-row flex-wrap w-full justify-between items-center px-5'>
      <div className='w-32 h-24 bg-pink-400'></div>
    </div>
    <div className='w-full flex flex-row justify-end cursor-pointer' onClick={handleTextSelect}>
      <div className='px-5 py-1 my-2 bg-blue-600 hover:bg-blue-500 text-lg font-semibold text-white'>Select</div>
    </div>

  </div>);
};




export default TextModal;
