import firebase from 'firebase/app'
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"
import { firebaseConfig } from './firebaseConfig'

firebase.initializeApp(firebaseConfig)


export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

export const setDocument = (collection, doc, data) => {
    return db.collection(collection).doc(doc).set(data, { merge : true});
}

export const currentUser = () => auth.currentUser


