import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/common/Loader';
import { db, storage } from 'src/Firebase';
import { setPhotoObjects } from 'src/state/Actions/sceneActions';
import { hideModal } from 'src/state/Actions/uiActions';
/* eslint-ignore line */
// import src from '../bin/image.jpg'



const PhotoModal = () => {
  const dispatch = useDispatch()
  const sceneReducer = useSelector((state)  =>state.sceneReducer)
  const [tab, setTab] = useState(1)
  const handlePhotoSelect = async(caption = "Photo", ext = "jpg", url = null, setStatus) => {
    var metadata = {
      contentType: 'image/' + ext,
    };
    var name = "Photo_"+ Date.now()
    var mediaRef = `scenes/${sceneReducer.id}/image_files/${name}.${ext}`
    setStatus("loading")
    const blob = await fetch(url).then(url => url.blob())
    
    const object = {
      id : `obj_${Date.now()}`,
      type :`photo`,
      name,
      caption,
      src : "",
      vars : {
      },
      mediaRef,
      position : {x : 0, y : 0, z : 0},
      rotation : {x : 0, y : 0, z : 0},
      scale : {x : 1, y : 1, z : 1},
      isVisible : true,
      actions : [],
      objectType : 'photo'
    }
    await storage.ref().child(mediaRef).put(blob, metadata)
    // await 
    

    db.collection("scenes").doc(sceneReducer?.id).set({
      photoObjects : [...sceneReducer.photoObjects, object]
    },{merge : true})
        .then(_ => {
          dispatch(setPhotoObjects([...sceneReducer.photoObjects, object]))
          dispatch(hideModal())
        })
  }

  return (
    <div className='px-12 h-96 flex flex-col'>
      <div className='flex flex-row justify-around items-center'>
        <div className={`px-5 py-3 w-full ${tab === 1 ? "bg-white blue-600 border-l-2" : "text-white bg-blue-600 rounded-br-2xl cursor-pointer"} font-extrabold text-lg`} onClick={() => setTab(1)}>Upload Image</div>
        <div className={`px-5 py-3 w-full ${tab === 2 ? "bg-white text-blue-600 border-r-2" : "text-white bg-blue-600 rounded-bl-2xl cursor-pointer"} font-extrabold text-lg`} onClick={() => setTab(2)}>Choose From Library</div>
      </div>
      {
        tab === 1 ? <UploadTab onClick={handlePhotoSelect} /> : <LibraryTab />
      }
    </div>
  );
};


const UploadTab = ({onClick}) => {
  const [imageUrl, setImageUrl] = useState("")
  const [ext, setExt] = useState("")
  const [caption, setCaption] = useState("Image")
  const [status, setStatus] = useState("upload")

return(
  <div className='flex flex-row justify-between border-l-2 border-r-2 border-b-2 px-3 py-7 mb-8 pt-12'>
        {status === "upload" ? 
        <div
          className='h-56 flex flex-col justify-center items-center px-5 border-2 border-dashed border-blue-600 rounded w-full'>
          <div>
            <input 
              onChange={(e) => {
                const _caption = e.target.files[0].name.split(".")
                setExt(_caption.pop())
                setCaption(_caption.toString())
                setImageUrl(window.URL.createObjectURL(e.target.files[0]))
                setStatus("preview")
              }} 
              accept=".jpeg, .jpg, .png" type="file" id="image-upload-btn" hidden
            />
            <label htmlFor="image-upload-btn" className="btn-primary cursor-pointer">Upload File</label>
          </div>
          <h4 className="text-center mt-3 font-sans font-medium">Accepted files .jpeg, .jpg, .png <br />Max 5MB</h4>
        </div>
        :status === "preview" ?
        <div className='flex w-full flex-col justify-between'>
          <div className="text-center flex-col-center w-full">
                  <img className='w-36 h-36' src={imageUrl} alt='Error' />
              <div className="flex-row-center">        
                  <input type="text" value={caption} onChange={(e) => setCaption(e.target.value)} />
                  <span>.{ext}</span>
              </div>
          </div>
          <div className='flex w-full justify-end'>
            <button onClick={() => onClick(caption, ext, imageUrl, setStatus)} className="btn-primary mt-4 rounded-sm ml-6">Upload</button>
          </div>
        </div>
        :
        <div className='flex-col-center h-64 items-center w-full'>
          <Loader />
          <h1>Uploading, Please wait...</h1>
        </div>
        
        }
    </div>
)
}

const LibraryTab =() => {
  const photos = [1,2,3,4,5,6,7,8,9,10,11,12,13]
  return(
    <div>
      {/* border-l-2 border-r-2 border-b-2 px-3 py-7 mb-8 pt-12 */}
      <h2 className='text-center text-2xl font-extrabold my-3'>My Library </h2>
      <div className='flex flex-row flex-wrap justify-start h-64 overflow-y-auto' style={{width : "600px"}}>
        {
          photos.map(photo => <Box key={photo} i={photo} />)
        }
      </div>
    </div>
  )
}

const Box = ({i}) => (<div className='w-24 h-24 bg-red-600 mx-1 my-1 flex items-end justify-center text-white'>Photo {i}.jpeg</div>)


export default PhotoModal;
