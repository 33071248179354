import React from 'react'

export default function index() {
return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
            <div
                className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
            </div>
            <div className="relative px-4 py-10  hover:shadow-2xl bg-white shadow-lg sm:rounded-3xl sm:p-20">
                <div className="flex absolute top-1 mt-8 gap-10 justify-center left-36">
                    <div className="h-36 w-5 bg-gray-300 opacity-10 animate-pulse  transform -rotate-12 rounded-full">
                    </div>
                    <div className="h-36 w-5 bg-gray-300 opacity-10 animate-pulse  transform  rounded-full"></div>
                    <div className="h-36 w-5 bg-gray-300 opacity-10 animate-pulse  transform rotate-12 rounded-full">
                    </div>
                </div>
                <div className="w-64 h-72 cursor-pointer bg-blue-500 rounded-b-3xl mt-28 mr-14"></div>
                <div className="absolute h-40 cursor-pointer w-32 top-56 right-20 border-8 border-blue-500 rounded-b-full "></div>
            </div>
        </div>
    </div>
)
}