export const setVisibility = (sceneState,type, id) => {
    const { photoObjects, videoObjects, model3DObjects,textObjects, primitiveObjects,lightObjects } = sceneState
    switch(type){
        case "Photo":
            return {
                ...sceneState,
                photoObjects : photoObjects.map(object => object.id === id ? {...object, isVisible : !object.isVisible} : object)
            }
        case "Video":
            return {
                ...sceneState,
                videoObjects : videoObjects.map(object => object.id === id ? {...object, isVisible : !object.isVisible} : object)
            }
            case "Model 3D":
        return {
            ...sceneState,
            model3DObjects : model3DObjects.map(object => object.id === id ? {...object, isVisible : !object.isVisible} : object)
        }
        case "Text":
        return {
            ...sceneState,
            textObjects : textObjects.map(object => object.id === id ? {...object, isVisible : !object.isVisible} : object)
        }
        case "Primitives":
        return {
            ...sceneState,
            primitiveObjects : primitiveObjects.map(object => object.id === id ? {...object, isVisible : !object.isVisible} : object)
        }
        case "Light":
        return {
            ...sceneState,
            lightObjects : lightObjects.map(object => object.id === id ? {...object, isVisible : !object.isVisible} : object)
        }
        default : 
        return sceneState
    }
}

export const updateObject = (sceneState,type, id, payload) => {
    const { photoObjects, videoObjects, model3DObjects,textObjects, primitiveObjects,lightObjects } = sceneState
    // console.log(payload);
    switch(type){
        case "photo":
            return {
                ...sceneState,
                photoObjects : photoObjects.map(object => object.id === id ? {...object, ...payload} : object)
            }
        case "video":
            return {
                ...sceneState,
                videoObjects : videoObjects.map(object => object.id === id ? {...object, ...payload} : object)
            }
            case "3D":
        return {
            ...sceneState,
            model3DObjects : model3DObjects.map(object => object.id === id ? {...object, ...payload} : object)
        }
        case "text":
        return {
            ...sceneState,
            textObjects : textObjects.map(object => object.id === id ? {...object, ...payload} : object)
        }
        case "primitive":
        return {
            ...sceneState,
            primitiveObjects : primitiveObjects.map(object => object.id === id ? {...object, ...payload} : object)
        }
        case "light":
        return {
            ...sceneState,
            lightObjects : lightObjects.map(object => object.id === id ? {...object, ...payload} : object)
        }
        default : 
        return null
    }
}

export const copyObject = (sceneState,type, id) => {
    const { photoObjects, videoObjects, model3DObjects,textObjects, primitiveObjects,lightObjects } = sceneState
    var obj
    switch(type){
        case "Photo":
            obj = photoObjects.find(obj => obj.id === id)
        return {
            ...sceneState,
            photoObjects : [...photoObjects, {
                ...obj,
                name : obj.name.split("_")[0] + "_" + Date.now(),
                id : obj.id.split("_")[0] + "_" + Date.now(),
                }]
        }
        case "Video":
            obj = videoObjects.find(obj => obj.id === id)
        return {
            ...sceneState,
            videoObjects : [...videoObjects, {
                ...obj,
                name : obj.name.split("_")[0] + "_" + Date.now(),
                id : obj.id.split("_")[0] + "_" + Date.now(),
                }]
        }
            case "Model 3D":
                obj = model3DObjects.find(obj => obj.id === id)
        return {
            ...sceneState,
            model3DObjects : [...model3DObjects, {
                ...obj,
                name : obj.name.split("_")[0] + "_" + Date.now(),
                id : obj.id.split("_")[0] + "_" + Date.now(),
                }]
        }
        case "Text":
            obj = textObjects.find(obj => obj.id === id)
        return {
            ...sceneState,
            textObjects : [...textObjects, {
                ...obj,
                name : obj.name.split("_")[0] + "_" + Date.now(),
                id : obj.id.split("_")[0] + "_" + Date.now(),
                }]
        }
        case "Primitives":
            obj = primitiveObjects.find(obj => obj.id === id)
        return {
            ...sceneState,
            primitiveObjects : [...primitiveObjects, {
                ...obj,
                name : obj.name.split("_")[0] + "_" + Date.now(),
                id : obj.id.split("_")[0] + "_" + Date.now(),
                }]
        }
        case "Light":
            obj = lightObjects.find(obj => obj.id === id)
        return {
            ...sceneState,
            lightObjects : [...lightObjects, {
                ...obj,
                name : obj.name.split("_")[0] + "_" + Date.now(),
                id : obj.id.split("_")[0] + "_" + Date.now(),
                }]
        }
        default : 
        return sceneState
    }
}


export const deleteObject = (sceneState,type, id) => {
    const { photoObjects, videoObjects, model3DObjects,textObjects, primitiveObjects,lightObjects } = sceneState
    switch(type){
        case "Photo":
            return {
                ...sceneState,
                photoObjects : photoObjects.filter(object => object.id !== id)
            }
        case "Video":
            return {
                ...sceneState,
                videoObjects : videoObjects.filter(object => object.id !== id)
            }
            case "Model 3D":
        return {
            ...sceneState,
            model3DObjects : model3DObjects.filter(object => object.id !== id)
        }
        case "Text":
        return {
            ...sceneState,
            textObjects : textObjects.filter(object => object.id !== id)
        }
        case "Primitives":
        return {
            ...sceneState,
            primitiveObjects : primitiveObjects.filter(object => object.id !== id)
        }
        case "Light":
        return {
            ...sceneState,
            lightObjects : lightObjects.filter(object => object.id !== id)
        }
        default : 
        return sceneState
    }
}


export const findObject = (sceneState,type, name) => {
    const { photoObjects, videoObjects, model3DObjects,textObjects, primitiveObjects,lightObjects } = sceneState
    switch(type){
        case "photo":
            return photoObjects.find(object => object.id !== name)
        case "video":
            return videoObjects.find(object => object.id !== name)
        case "3D":
            return  model3DObjects.find(object => object.id !== name)
        case "text":
            return textObjects.find(object => object.id !== name)
        case "primitive":
            // Don't know why I used id here 
            return primitiveObjects.find(object => object.id !== name)
        case "light":
            return lightObjects.find(object => object.id !== name)
        default : 
            return null
    }
}



export const dataURLToBlob = function(dataURL)
{
    var BASE64_MARKER = ";base64,";
    if (dataURL.indexOf(BASE64_MARKER) == -1)
    {
        var parts = dataURL.split(",");
        var contentType = parts[0].split(":")[1];
        var raw = decodeURIComponent(parts[1]);

        return new Blob([raw], {type: contentType});
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
}