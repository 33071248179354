import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

interface modal{
    title? : string,
    children :null | JSX.Element,
    footer? : null | JSX.Element,
    onClose? : any,
    modalCss? : string,
    showModal? : any,
    setShowModal? : any,
    onClick? : any
}

export default function Modal({
    title = "Modal Title",
    children,
    footer = null,
    onClose,
    modalCss = "",
    showModal = false,
    setShowModal,
    } : modal) {
return (
    <>

        {showModal ? (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="min-w-1/2 relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ${modalCss}`}>
                        {/*header*/}
                        <div
                            className="px-4 flex flex-row items-center justify-between h-16 border-b border-solid border-blueGray-200 rounded-t">
                            <div className="text-3xl font-extrabold font-sans text-gray-900" >
                                {title}
                            </div>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={()=> setShowModal(false)}
                                >
                                <svg onClick={onClose} className="ml-auto fill-current text-black w-6 h-6 cursor-pointer"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                                    <path
                                        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                                </svg>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto">
                            {children}
                        </div>
                        {/*footer*/}
                        <div className="flex items-center p-3 h-16 justify-end border-t border-solid border-blueGray-200 rounded-b">
                            {footer}
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
        ) : null}
    </>
    );
    }




    // const _Modal = () => {

    // return show ?(
    // <div className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-400">
    //     <div className="bg-white rounded-lg w-1/2 ">
    //         <div className="flex flex-col items-start p-4">
    //             <div className="flex items-center w-full">
    //                 <div className="text-gray-900 font-medium text-lg">{title}</div>
    //                 <svg onClick={onClose} className="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
    //                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    //                     <path
    //                         d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
    //                 </svg>
    //             </div>
    //             <hr />
    //             {
    //             children
    //             }
    //             <hr />
    //             <div className="ml-auto">
    //                 {
    //                 actions ? actions : <button className="btn bg-gray-500 hover:bg-gray-700 py-3">Ok</button>
    //                 }
    //             </div>
    //         </div>
    //     </div>
    // </div>
    // ):null}