import React from 'react';
import { BsBox, BsCircle } from 'react-icons/bs'
import { BiPyramid, BiCylinder } from 'react-icons/bi'
import { GiDonut } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux';
import { setPrimitiveObjects } from 'src/state/Actions/sceneActions';
import { hideModal } from 'src/state/Actions/uiActions';


const PrimitiveModal = () => {
  const [selected, setSelected] = React.useState<String>("")
  const dispatch = useDispatch()
  const { primitiveObjects } = useSelector((state:any)  =>state.sceneReducer)

  const handlePrimitiveSelect = () => {
    const d = Date.now()
    const object = {
      id : `obj_${d}`,
      type :`${selected.toLowerCase()}`,
      name : selected + "_"+ d,
      caption : selected,
      vars : getPrimitiveVars(selected),
      shape : selected,
      position : {x : 0, y : 0, z : 0},
      rotation : {x : 0, y : 0, z : 0},
      scale : {x : 1, y : 1, z : 1},
      isVisible : true,
      actions: [],
      objectType : 'primitive'
    }
    dispatch(setPrimitiveObjects([...primitiveObjects, object]))
    dispatch(hideModal())
  }

  return (
    <div className='px-12'>
    <h2 className='text-3xl text-center font-extrabold my-4'>Select Primitive</h2>
    <div className='flex flex-row flex-wrap w-full justify-between items-center px-5'>
      <Primitive
        setSelected={() => setSelected("box")}
        selected={selected === "box"     ? true : false} size={100} Icon={BsBox} text={"Box"} />
      <Primitive
        setSelected={() => setSelected("circle")}
        selected={selected === "circle"  ? true : false} size={100} Icon={BsCircle} text={"Circle"} />
      <Primitive
        setSelected={() => setSelected("cone")}
        selected={selected === "cone"    ? true : false} size={100} Icon={BiPyramid} text={"Cone"} />
      <Primitive
        setSelected={() => setSelected("cylinder")}
        selected={selected === "cylinder"? true : false} size={100} Icon={BiCylinder} text={"Cylinder"} />
      <Primitive
        setSelected={() => setSelected("torus")}
        selected={selected === "torus"   ? true : false} size={100} Icon={GiDonut} text={"Torus"} />
    </div>
    <div className='w-full flex flex-row justify-end cursor-pointer' onClick={handlePrimitiveSelect}>
      <div className='px-5 py-1 my-2 bg-blue-600 hover:bg-blue-500 text-lg font-semibold text-white'>Select</div>
    </div>

  </div>
  );
};


const Primitive = ({size, Icon, text, selected, setSelected} : {size : number, Icon : any, text : String, selected : boolean, setSelected:any}) => {
  const [hover, setHover] = React.useState(false)
return(
  <div 
      onClick={setSelected}
      onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} 
      className={`mx-2 my-2 flex flex-col items-center cursor-pointer ${hover || selected? "text-blue-500" : "text-black"}`}>
    <Icon size={size} color={hover || selected? "#3b82f6" : "black"} />
    <span className='text-xl my-3 font-extrabold '>{text}</span>
  </div>
)
}


const getPrimitiveVars = (type: String) => {
  switch (type) {
    case "box":
      return [
            {
            name : 'width',
            value : 1,
            },
            {
              name : 'height',
              value : 1,
            },
            {
              name : 'depth',
              value : 1,
            },
            {
              name : 'width-segments',
              value : 1,
            },
            {
              name : 'height-segments',
              value : 1,
            },
            {
              name : 'depth-segments',
              value : 1,
            },
            {
              name : 'color',
              type : 'color',
              value : '#fb923c'
            }
        ]
    case "circle":
      return [
            {
            name : 'radius',
            value : 1,
            },
            {
              name : 'segments',
              value : 1,
            },
            {
              name : 'theta-start',
              value : 1,
            },
            {
              name : 'theta-length',
              value : 2 * Math.PI,
            },
            {
              name : 'color',
              type : 'color',
              value : "#7f1d1d",
            }
        ]

    case "cone":
      return [
            {
            name : 'radius',
            value : 1.3,
            },
            {
              name : 'height',
              value : 3,
            },
            {
              name : 'radial-segments',
              value : 8,
            },
            {
              name : 'height-segments',
              value : 1,
            },
            {
              name : 'open-ended',
              type : 'boolean',
              value : false
            },
            {
              name : "theta-start",
              value : 1
            },
            {
              name : 'theta-length',
              val : 2 * Math.PI
            },
            {
              name : 'color',
              type : 'color',
              value : "#a3e635",
            }
        ]
    case "cylinder":
      return [
            {
            name : 'radius-top',
            value : 1,
            },
            {
              name : 'radius-bottom',
              value : 1,
            },
            {
              name : 'height',
              value : 3,
            },
            {
              name : 'radial-segments',
              value : 80,
            },
            {
              name : 'height-segments',
              value : 1
            },
            {
              name : 'open-ended',
              type : 'boolean',
              value : false
            },
            {
              name : "theta-start",
              value : 0
            },
            {
              name : 'theta-length',
              val : 2 * Math.PI
            },
            {
              name : 'color',
              type : 'color',
              value : "#c026d3",
            }
        ]
    case "torus":
      return [
            {
            name : 'radius',
            value : 1,
            },
            {
              name : 'tube',
              value : 0.5,
            },
            {
              name : 'radial-segments',
              value : 80,
            },
            {
              name : 'tubular-segments',
              value : 50,
            },
            {
              name : 'arc',
              value : Math.PI * 2
            },
            {
              name : 'color',
              type : 'color',
              value : "#10b981",
            }
      ]
      default:
        return []
  }
}

export default PrimitiveModal;
