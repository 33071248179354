import React, { useState } from 'react'
import DropdownFilter from './SceneDropDown'

const CreateScene = ({sceneName, setSceneName, hadnleAddScene,setSceneType } : any) => {
    const [step, setStep] = useState<number>(1)
    const [selected, setSelected] = useState<number>(0)
    const [tracking, setTracking] = useState<number>(0)
    // const [sceneType, setSceneType] = useState<string>("")

return(
step === 1 ? <div className="h-40 w-full py-7 bg-gray-900 text-white flex-col-center">
    <h2 className="my-3 w-1/2 text-left">Enter Scene Name..</h2>
    <input value={sceneName} onChange={e=> setSceneName(e.target.value)} className="py-1 px-3 w-1/2 text-black"
    type="text" placeholder="Blue Dragon" />
    <button onClick={() => setStep(3)}
        className="px-7 py-1 my-5 bg-white rounded-sm text-blue-500 text-xl flex-col-center hover:bg-blue-500 hover:text-white">Next Step</button>
</div>
// : step === 2 ?
// // Geo Location step
// <div className="h-40 w-full py-7 bg-gray-900 text-white flex-col-center">
//     <h2 className="my-3 w-1/2 text-left">Select Location</h2>
//     <div className="w-1/2 flex flex-row justify-center">
//         <DropdownFilter selected={selected} setSelected={setSelected} />

//     </div>
//     <div className="flex flex-row justify-between w-1/2">
//         <button className="primary">Back</button>
//         <button onClick={hadnleAddScene}
//             className="px-7 py-1 my-5 bg-white rounded-sm text-blue-500 text-xl flex-col-center hover:bg-blue-500 hover:text-white">
//                 Create Scene
//         </button>
//     </div>
// </div>
:
<div className="h-96 w-full py-4 bg-gray-900 text-white flex-col-center">
    <h2 className="my-2 w-1/2 text-left">Select Type</h2>
    <div className="flex flex-row">

        <div className="w-2/3 flex-col-center h-72 overflow-y-visible">
            <Card 
                onClick={() => {
                    // setSceneType("")
                    setSelected(1)
                }} 
                title="Object Tracking" selected={selected === 1 && true} />
            <Card 
                onClick={() => {
                    setSceneType("geolocation")
                    setSelected(2)
                }} 
                title="Geolocation" selected={selected === 2 && true} />
        </div>

        {selected === 1 && 
            <div className="h-full flex-row-center w-20">
                <span className="text-6xl">{">"}</span>
            </div>}
        {/* Select Tracking Type */}
        {selected === 1 && <div className="w-2/3 flex-col-center h-72 overflow-y-visible">
            <Card 
                onClick={() => {
                    setSceneType("marker")
                    setTracking(1)}
                } 
                title="Marker" selected={tracking === 1 && true} />
            <Card 
                onClick={() => {
                    setSceneType("image")
                    setTracking(2)}
                } 
                title="Image Tracking" selected={tracking === 2 && true} />
        </div>
        }
    </div>
    <div className="flex flex-row justify-between w-1/2">
        <button className="primary" onClick={() => setStep(1)}>Back</button>
        <button onClick={hadnleAddScene}
            className="px-7 py-1 my-5 bg-white rounded-sm text-blue-500 text-xl flex-col-center hover:bg-blue-500 hover:text-white">
                Create Scene
        </button>
    </div>
</div>
)
}





const Card = ({ title = "", selected = false, onClick} : any) => {
    const [hover, setHover] = useState<boolean>(false)
return(
    
    <div   
        onMouseLeave={() => setHover(false)} 
        onMouseOver={() => setHover(true)} 
        className={`rounded-xl mx-1 px-8 py-1 my-2 cursor-pointer flex-col-center ${hover ? "bg-gray-700" : "bg-gray-100"} ${selected && "bg-gray-700"}`} 
        style={{width : "300px", height : "300px"}} onClick={onClick}
        >
        {selected && <div className="w-6 h-6"><CheckIcon/></div>}

            <h4 className={`my-2 text-2xl heading font-extrabold font-custom ${hover ? "text-white" : "text-black"} ${selected && "text-white"}`}>{title}</h4>
    </div>
)}



const CheckIcon = () => <svg fill="#fafafa" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
<metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
<g><path d="M990,210.8L924.3,145L345.1,724.2L75.7,454.8L10,520.6l325.5,325.5l9.2,8.9l9.8-8.9L990,210.8L990,210.8z"/></g>
</svg>

export default CreateScene
