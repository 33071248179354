import React, { useState, useEffect } from 'react'
import LeftMenu from './LeftMenu'
import Modal from './Modals'
import RightControls from './RightControls'
import Scene from './Scene'
import { GrRotateRight } from 'react-icons/gr'
import { FaMousePointer } from 'react-icons/fa'
import { MdOpenInFull } from 'react-icons/md'
import Menu from './Menu'
import { useDispatch, useSelector } from 'react-redux'
import { disposeUiReducer, setTransformMode, setUiReducer } from 'src/state/Actions/uiActions'
import Loader from 'src/components/common/Loader'
import { db, storage } from 'src/Firebase'
import { disposeSceneState, setSceneProperties } from 'src/state/Actions/sceneActions'



const Index = (props : any) => {
    const [status, setStatus] = useState("loading")
    const { id } = props.match.params
  const dispatch = useDispatch()
  const {transform_mode, current_mesh_data } = useSelector((state : any) => state.uiReducer)

  useEffect(() => {
    db.collection("scenes").doc(id).get()
    .then(async snap => {
        if(snap.exists){
            const data : any = snap.data()
            dispatch(setSceneProperties(snap.data()))
            setStatus("loaded")
            const qrRef = await storage.ref().child(data.qrRef)
            .getDownloadURL()
            .then(url => url)
            .catch(_ => null)
            const controlImgRef = data.controls.ref && await storage.ref().child(data.controls.ref)
            .getDownloadURL()
            .then(url => url)
            .catch(_ => null)
            dispatch(setUiReducer({
                qrRef,
                controlImgRef
            }))
        } else {
            setStatus("404")
        }
    })
    .catch(_ => setStatus("Error"))

    return () => {
        setStatus('loading')
        dispatch(disposeSceneState())
        dispatch(disposeUiReducer())
    }
  },[])


return status === "loaded" ? (
        <div className="flex flex-col disable-select">
            <Menu />
            <div className="flex flex-row w-full" style={{height : "90vh"}}>
                <div className="w-1/4">
                    <LeftMenu id={id} />
                </div>
                {/* Center */}
                <div className="w-1/2 bg-white">
                    <Scene />
                </div>
                <div className="w-1/4 h-screen">
                    {current_mesh_data.name &&
                    <div className='border-2 border-gray-400 bg-gray-200 rounded-lg w-20 h-72 transform-control flex-col-center justify-between px-1 py-4'>
                        <div onClick={() => dispatch(setTransformMode("translate"))} className='flex-col-center cursor-pointer'>
                            <div className={`${transform_mode === 'translate'? 'bg-blue-500' : 'bg-white'} rounded-lg p-2 mb-2`}>
                                <FaMousePointer color={transform_mode === 'translate' ? 'white' : '#9ca3af'} size={30} />
                            </div>
                            <span className='text-xs'>Move</span>
                        </div>
                        <div onClick={() => dispatch(setTransformMode("rotate"))} className='flex-col-center cursor-pointer'>
                            <div className={`${transform_mode === 'rotate'? 'bg-blue-500' : 'bg-white'} rounded-lg p-2 mb-2`}>
                                <GrRotateRight color={transform_mode === 'rotate' ? 'white' : '#9ca3af'} size={30} />
                            </div>
                            <span className='text-xs'>Rotate</span>
                        </div>
                        <div onClick={() => dispatch(setTransformMode("scale"))} className='flex-col-center cursor-pointer'>
                            <div className={`${transform_mode === 'scale'? 'bg-blue-500' : 'bg-white'} rounded-lg p-2 mb-2`}>
                                <MdOpenInFull color={transform_mode === 'scale' ? 'white' : '#9ca3af'} size={30} />
                            </div>
                            <span className='text-xs'>Scale</span>
                        </div>
                    </div>}
                    <RightControls />
                </div>
            </div>
            <div>
            </div>   
            <Modal />
        </div>
    ):<div className='h-screen w-full flex-col-center'>
        {status === "loading"?<>
        <Loader />
        <h3 className='my-2 text-xl font-bold'>Please wait...</h3>
        </>
        :status === "404" ? <>
            <h1>Scene Not Found</h1>
        </>:null}
    </div>
}



export default Index
