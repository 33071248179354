import React, {  useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import Menu from '../../components/common/Menu'
import Modal from '../../components/common/ModalFooter'
import firebase from 'firebase/app'
import { auth, db, storage } from '../../Firebase';
import Loader from '../../components/common/Loader';
import { useHistory } from 'react-router';
import Footer from '../../components/common/Footer';
import CreateScene from './CreateScene';
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { BsImageAlt } from 'react-icons/bs'
import { GiGearStickPattern } from 'react-icons/gi'

const CompaignView : React.FC = (props : any) => {
    const { id } = props.match.params
    const history = useHistory()
    const [addScene, setAddScene] = useState(false)
    const [compaignData, setCompaign] = useState<any>(null)
    const [activate, setActivate] = useState<boolean>(false)
    const [isActivated, setActivated] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(true)
    const [activating, setActivating] = useState<boolean>(true)
    const [activeText, setActiveText] = useState<string>("Activate")
    const [sceneName, setSceneName] = useState<string>('')
    const [sceneAdding, setSceneAdding] = useState<boolean>(false)
    const [rename, setRename] = useState<boolean>(false)
    const [isRenaming, setRenaming] = useState<boolean>(false)
    const [renameText, setRenameText] = useState('')
    const [sceneType, setSceneType] = useState<string>("")

    const disposeState = () => {
        setAddScene(false)
        setCompaign(null)
        setActivate(false)
        setActivated(false)
        setLoading(true)
        setActiveText("Activate")
        setSceneName('')
        setSceneAdding(false)
        setRename(false)
        setRenaming(false)
        setRenameText('')
        setSceneType('')
    }
    useEffect(() => {
        let isUnmount = false
        if(!isUnmount)
        {
            loadCompaign()
        }
        console.log('isUnmount ', isUnmount );
        return () => {
            console.log("unmounting state")
            isUnmount = true
            setCompaign(null)
            setLoading(true)
            setActivated(false)
        }
    },[activating])

const loadCompaign = () => {
    db.collection("compaigns").doc(id).get()
    .then(snap => {
        const data : any = snap.data()
        setCompaign(data)
        setLoading(false)
        if(data.status === 'active'){
            setActivated(true)
        }
    })
}

const hadnleAddScene = () => {
    if(sceneType === ""){
        alert("please select scene type to proceed")
        return
    }
    const user = auth.currentUser
    if(!user){
        alert("User not authorized")
        return
    }

    if(!isActivated){
        alert("Please activate compaign to add scenes")
        return
    }
    setSceneAdding(true)

    const copyObj = {
        status: "draft",
        title : sceneName,
        compaignId : id,
        sceneType,
        qrRef : "",
        photoObjects: [],
        videoObjects: [],
        model3DObjects: [],
        textObjects: [],
        primitiveObjects: [],
        lightObjects: [], 
        controls : {
            location : {lat : null, lng : null},
            enabled : false,
            ref : null
        },
        createdBy : user.uid,
        createdAt : firebase.firestore.FieldValue.serverTimestamp(),
    }
    db.collection("scenes").add(copyObj)
    .then(async(data) => {
        const url = `https://api.qrserver.com/v1/create-qr-code/?size=320x320&data=https://lens.augle.io/scene?id=${data.id}`
        const qr_image : any = await fetch(url).then(res => res.blob())
            storage.ref().child(`scenes/${data.id}/qr_image.png`).put(qr_image)
            .then(async(res) => {
                data.set({
                    // qr_ref : `qr_images/${data.id}.png`, //old value
                    qrRef : `scenes/${data.id}/qr_image.png`,
                    id : data.id
                },{merge : true})
                
            })
        db.collection("compaigns").doc(id)
        .set({
            scenes : firebase.firestore.FieldValue.arrayUnion(data.id)
        },{merge : true})
        .then(_ => {
            loadCompaign()
            setAddScene(!addScene)
            setSceneType("")
            setSceneName("")
            setSceneAdding(false)
        })
    })
    .catch(e => alert(e))
}

const _activateCompaign = () => {
    setActivate(true)
    db.collection("compaigns").doc(id).set({
        status : "active"
    },{merge : true})
    .then(_ => {
        setActiveText("Active")
        setActivated(true)
        setActivate(false)
    })
    .catch(e => {
        alert("Error while activating compaign")
        setActiveText("Activate")
    })
    //alert("activate")
}
const renameCompaign = () => {
    // setRename(true)
    setRenaming(true)
    db.collection("compaigns").doc(id).set({
        title : renameText
    },{merge : true})
    .then(() => {
        setRename(false)
        setRenaming(false)
    })
    .catch(_ => {
        setRename(false)
        alert("Error while renaming ")})
}
const deleteCompaign = () => {
    let confirm = window.confirm("Do you really want to delete this compaign?")
    if(!confirm) {
        return
    }
    setActivate(true)
    db.collection("compaigns").doc(id).delete().then(() => {
        setActivate(false)
        history.push({
            pathname: '/compaigns',
        });
        console.log("Compaign Deleted")
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
}
return id ?(
<div>
    <Helmet>
        <title>{compaignData?.title}</title>
    </Helmet>
    <Menu />
    <div className="mt-28 min-h-screen">
        <div className="">
            <div className="my-14">
                <div className="w-full flex flex-row justify-end px-14">
                    <DropDown isActivated={isActivated} activate={_activateCompaign} _delete={deleteCompaign}
                        rename={()=> setRename(true)} />
                </div>

                {/* {!isActivated && <div onClick={activateCompaign} className="w-full flex flex-row justify-end px-14">
                    <div className="my-2 rounded-2xl text-white text-md 
                font-black bg-green-500
                w-min px-3 tracking-widest font-sans
                cursor-pointer
                ">
                        {activeText}
                    </div>
                </div>} */}

                {compaignData &&
                <div className="flex flex-row flex-wrap px-20">
                    {compaignData.scenes.length > 0?
                    compaignData?.scenes.map((scene : any, index : any) =>
                    <SceneView key={index} scene={scene} />)
                    : <div className="flex-col-center bg-gray-50 w-full h-96">
                        No Scenes
                        <button onClick={()=> {
                            if(!isActivated){
                            alert("Please activate the compaign first")
                            return
                            }
                            setAddScene(true)
                            }} className="btn-primary">Add Scene</button>
                    </div>
                    }
                </div>
                }
                {
                isLoading &&
                <Loader />
                }
                <Modal showModal={addScene} setShowModal={setAddScene}>
                    <div className="w-full flex-col-center">
                        {!sceneAdding ?
                        <CreateScene sceneName={sceneName} setSceneName={setSceneName} hadnleAddScene={hadnleAddScene} setSceneType={setSceneType} />
                        :
                        <div>
                            <Loader />
                        </div>
                        }
                    </div>
                </Modal>

                {/* Compaign Action model */}
                <Modal modalCss="px-20 py-6" showModal={activate} setShowModal={setActivate}>
                    <div className="w-full">
                        <Loader />
                        <h1 className="text-center my-5">Please Wait...</h1>
                    </div>
                </Modal>

                <Modal modalCss="py-6" title="Rename" showModal={rename} setShowModal={setRename}>
                    <div className="mx-20 flex flex-col flex-wrap justify-center items-center">
                        <h1 className="text-center my-5">Enter New Name</h1>
                        <input value={renameText} onChange={(e)=> setRenameText(e.target.value)} placeholder="Compaign
                        Name" className="mt-1 block w-1/2 border-none bg-gray-100 h-11 rounded-xl focus:rounded-xl
                        focus:bg-blue-100 focus:ring-0 px-5" />
                        <button className="btn-primary my-4 " onClick={renameCompaign}>{isRenaming ? "Wait..." :
                            "Rename"}</button>
                    </div>
                </Modal>
            </div>
        </div>
        {
        compaignData && compaignData.scenes.length > 0 &&
        <div className="w-full flex-col-center">
            <button onClick={()=> {
                if(!isActivated){
                alert("Please activate the compaign first")
                return
                }
                setAddScene(true)
                }} className="px-10 h-10 text-white bg-gray-500 rounded-lg hover:bg-gray-700">
                Add Scene
            </button>
        </div>
        }

    </div>
    <Footer />
</div>
)
:(<div>Id is invalid</div>)
}

const SceneView = ({scene} : any) => {

    const [sceneData, setSceneData] = useState<any>(null)
    const [thumbnail, setThumbnail] = useState<any>(null)
    const history = useHistory()
    useEffect(() => {
        let isUnmount = false
        if(!isUnmount){
            storage.ref().child(`scenes/${scene}/screenshot.png`)
            .getDownloadURL()
            .then(url => setThumbnail(url))
            .catch(_ => console.log("Error while getting scene thumbnail url"))
            db.collection("scenes").doc(scene).get()
            .then(snap => {
            setSceneData(snap.data())
        })
    }
    return () => {
        isUnmount = true
        setSceneData(null)
        setThumbnail(null)
    }
    },[])

        const openSceneEditor = () => {
        if(!sceneData)
        return
        history.push({
        pathname: '/editor/' + scene,
        });
        
        }
return sceneData ? (
        <div onClick={openSceneEditor}
            className="mx-4 my-2 w-48 rounded-lg shadow-xl hover:shadow-2xl border-2 hover:opacity-60 cursor-pointer flex flex-col justify-between py-1 items-center">
            {sceneData && 
                <>
                    <span className='w-full flex justify-end mr-1'>
                    {
                        sceneData.sceneType === "marker" ? <GiGearStickPattern className='text-green-500' size={23}/> 
                        : sceneData.sceneType === "geolocation" ? <HiOutlineLocationMarker className='text-red-600' size={23}/>
                        :sceneData.sceneType === "image" ? <BsImageAlt className='text-purple-600' size={23}/> : null
                    }
                    </span>
                    {
                        thumbnail ? <img className='h-36 w-36 my-0' src={thumbnail} alt={sceneData.sceneType} /> : <img className='h-36 w-36' src="/thumbnail.png" alt={sceneData.sceneType} />
                    }
                    <h3 className='text-center text-black my-1 font-black'>{sceneData.title}</h3>
                </>}
        </div>
        ) : <div/>
    }


    const DropDown = ({showModel, activate, rename, _delete, isActivated} : any) => {
        const [showMenu, setMenu] = useState<boolean>(false)
            return(
            <div className="flex justify-center">
                <div onClick={()=> {
                    setMenu(!showMenu)}} className="relative my-4">
                    <button className="relative z-10 block rounded-md bg-white p-2 focus:outline-none">
                        <svg className="h-6 w-6 text-gray-800 bg-gray-100" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd" />
                        </svg>
                    </button>

                    {showMenu && <>
                        <div className="fixed inset-0 h-full w-full z-10"></div>
                        <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
                            <span onClick={()=> {
                                if(!isActivated){
                                activate()
                                }
                                }} className={`block px-4 py-2 text-sm capitalize ${isActivated ? "text-gray-300" :
                                "text-gray-700 hover:bg-blue-500 hover:text-white"} cursor-pointer`}>
                                Activate
                            </span>
                            <span onClick={rename}
                                className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white cursor-pointer">
                                Rename
                            </span>
                            <span onClick={_delete}
                                className="block px-4 py-2 text-sm capitalize text-red-500 hover:bg-blue-500 hover:text-white cursor-pointer">
                                Delete
                            </span>
                        </div>
                    </>}
                </div>
            </div>
)}


export default CompaignView