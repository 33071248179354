import { CALL_ANIMATION, DELETE_OBJECT, DISPOSE_UI_REDUCER, HIDE_MODAL, SET_CURRENT_MESH, SET_CURRENT_MESH_DATA, SET_LOADING, SET_SAVING, SET_TRANSFORM_MODE, SET_UI_REDUCER, SHOW_MODAL } from "../ActionTypes/uiActionTypes";

export const setUiLoading = (payload) => ({
    type : SET_LOADING,
    payload
})

export const setSaving = (payload) => ({
    type : SET_SAVING,
    payload
})

export const callAnimation = (payload) => ({
    type : CALL_ANIMATION,
    payload
})

export const showModal = (payload) => ({
    type : SHOW_MODAL,
    payload
})
export const hideModal = () => ({
    type : HIDE_MODAL
})

export const setCurrentMesh = (payload) => ({
    type : SET_CURRENT_MESH,
    payload
})

export const setCurrentMeshData = payload => ({
    type : SET_CURRENT_MESH_DATA,
    payload
})

export const setTransformMode = payload => ({
    type : SET_TRANSFORM_MODE,
    payload
})


export const deleteObject = (payload) => ({
    type : DELETE_OBJECT,
    payload
})

export const setUiReducer = payload => ({
    type : SET_UI_REDUCER,
    payload
})

export const disposeUiReducer = () => ({
    type : SET_UI_REDUCER,
    payload : {
        show_modal : false,
        modal_content : "",
        current_mesh_data : {
          name : '',
          objectType : ''
        },
        transform_mode : "translate",
        is_loading : false,
        compaign : {
        },
        qrRef : null,
        controlImgRef : null,
        app_loading : false,
        saving_data : false,
        call_animation : "",
        tempVals : {
          tabOptions : null,
          currentSettings : "media",
          animation : null
        },
      }
})