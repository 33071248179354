import React, { Suspense, useRef } from 'react'
import { Canvas } from "@react-three/fiber"
import { useDispatch, useSelector } from 'react-redux'
import { OrbitControls } from '@react-three/drei';

import TransformControls from "./TransformControls"
import VideoObjects from '../Objects/VideoObject';
import ImageObjects from '../Objects/ImageObject';
import TextObjects from '../Objects/TextObject'
import PrimitiveObjects from '../Objects/PrimitiveObject'
import LightObjects from '../Objects/LightObjects'
import ThreeObjects from "../Objects/ThreeObject"
import { WebGLRenderer } from 'three'
const Scene = () => {
  const state = useSelector(state => state)
  const ref = useRef()
  const dispatch = useDispatch()
  const {type} = state.uiReducer.current_mesh_data


  return (
    <Canvas
    // orthographic={true}
    // flat={true}
    ref={ref}
    camera={{ position: [0, 10, 20], fov: 40, far: 10000 }}
    id="editor-scene"
    gl={canvas => {
      new WebGLRenderer({preserveDrawingBuffer : true, canvas})
    }}
    // camera={{ position: [0, 90, 0], fov: 40, far: 10000 }}
    >
      <ambientLight name='Default_Light' intensity={0.4} />
      {/* <pointLight position={[10, 10, 10]} /> */}
      <gridHelper
        name='Grid_Helper'
        args={[12, 120, `white`, `gray`]}
        />
          <PrimitiveObjects state={state} dispatch={dispatch} />
          <LightObjects state={state} dispatch={dispatch}  />
          <VideoObjects state={state} dispatch={dispatch} />
          <ImageObjects state={state} dispatch={dispatch} />
          <ThreeObjects state={state} dispatch={dispatch} />
          {/* <TextObjects state={state} dispatch={dispatch} /> */}

          {/* <Suspense fallback={null}>
            <TextObjects state={state} dispatch={dispatch} />
          </Suspense> */}
          <OrbitControls
            enableDamping={false}
            makeDefault
          />
          {/* <Suspense fallback={null}>
            <Text children='T' />
          </Suspense> */}
      {type === "marker" || type === "image" || type === "geolocation" ?
        null : <TransformControls state={state} dispatch={dispatch} />
      }
    </Canvas>
  )
}

export default Scene