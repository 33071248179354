import * as THREE from 'three'
import React, { useMemo, useRef, useLayoutEffect } from 'react'
import { extend, useLoader } from '@react-three/fiber'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import droid_sans from './blobs/droid_sans.blob'
import gentilis from './blobs/gentilis.blob'
import helvetiker from './blobs/helvetiker.blob'
import indie_flower from './blobs/indie_flower.blob'
import pacifico from './blobs/pacifico.blob'
import permanent_marker from './blobs/permanent_marker.blob'
import shizuru from './blobs/shizuru.blob'
import varela_round from './blobs/varela_round.blob'




extend({ TextGeometry })
export default function Text({ children = "Augle.io", vAlign = 'center', hAlign = 'center', size = 1.5, color = '#000000',dispatch = () => {}, state = {}, object={}, ...props }) {
  const fontNames = ['Droid Sans', 'Gentilis', 'Helvetiker', 'Indie Flower', 'Pacifico', 'Permanent Marker', 'Shizuru', 'Varela Round']

  const font = useLoader(FontLoader, getFont(fontNames[4]))
  const config = useMemo(
    () => ({ font, size: 22, height: 16, curveSegments: 26, bevelEnabled: true, bevelThickness: 6, bevelSize: 2.5, bevelOffset: 0, bevelSegments: 8 }),
    [font]
  )
  const mesh = useRef()
  useLayoutEffect(() => {
    const size = new THREE.Vector3()
    // mesh.current.geometry.computeBoundingBox()
    // mesh.current.geometry.boundingBox.getSize(size)
    mesh.current.position.x = hAlign === 'center' ? -size.x / 2 : hAlign === 'right' ? 0 : -size.x
    mesh.current.position.y = vAlign === 'center' ? -size.y / 2 : vAlign === 'top' ? 0 : -size.y
  }, [children])
  return (
    <group {...props} scale={[0.1 * size, 0.1 * size, 0.1]}>
      <mesh ref={mesh}>
        <textGeometry args={[children, config]} />
        <meshNormalMaterial />
      </mesh>
    </group>
  )
}


const getFont = (name) => {
  switch(name){
    case 'Droid Sans':
      return droid_sans
    case 'Gentilis':
      return gentilis
    case 'Helvetiker':
      return helvetiker
    case 'Indie Flower':
      return indie_flower
    case 'Pacifico':
      return pacifico
    case 'Permanent Marker':
      return permanent_marker
    case 'Shizuru':
      return shizuru
    case 'Varela Round':
      return varela_round
    default:
    return droid_sans
  }
}
