import React, { useEffect, useState } from 'react'
import LocationPicker from 'react-location-picker'
import { useSelector, useDispatch } from 'react-redux'
import { setSceneProperties } from 'src/state/Actions/sceneActions'
import { Tabs } from './Tabs'
import { BiCurrentLocation } from 'react-icons/bi'

const GeoLocationSettings = (props) => {
    // const [state, setState] = useState({
    //     btnSaveTxt : "Save"
    // })
    const dispatch = useDispatch()

    const { controls } = useSelector(state => state.sceneReducer)
    const [geoLocation, setGeoLocation] = useState({
        lat: 1.280734,
        lng: 103.839828
    })
    const handleLocationChange = ( data ) => {
        // Set new location
        // setGeoLocation(data.position);
        dispatch(setSceneProperties({
            controls : {
                location : geoLocation,
                enabled : true
            }
        }))
    }


    const loadCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setGeoLocation({
                    lat : position.coords.latitude,
                    lng : position.coords.longitude,
                })
                dispatch(setSceneProperties({
                    controls : {
                        location : {
                            lat : position.coords.latitude,
                            lng : position.coords.longitude
                        },
                        enabled : true
                    }
                }))
            })
        }
    } 

    return (
        <div className="w-full m-0 h-96">
            {/* <h2 className='my-7 text-2xl font-extrabold text-center'>Location Settings</h2> */}
            <div className=" justify-between items-center">
              {/* <Tab1 /> */}
                <div className="w-72 h-full">
                <div onClick={loadCurrentLocation} className='h-72'>
                    <LocationPicker
                        containerElement={ <div style={ {height: '100%'} } /> }
                        mapElement={ <div className="h-72" /> }
                        defaultPosition={controls.enabled ? controls.location : geoLocation}
                        onChange={handleLocationChange}
                    />
                    <BiCurrentLocation
                    className='bg-gray-100 rounded-full current-location text-black hover:text-blue-700 cursor-pointer p-2 shadow-xl hover:shadow-2xl'  
                    size={41} />
                </div>
                </div>
                {/* <span className="mx-4">OR</span> */}
                <div className="mt-1 flex flex-col justify-between px-5 py-2">
                    <div>
                        <span>Longitude</span> : <div  className="px-8 w-full border-2 bg-gray-100 h-10 rounded-lg shadow-md focus:bg-blue-100 focus:ring-0 flex-row-center">{geoLocation.lng.toFixed(8)}</div> 
                    </div>
                    <div>
                        <span>Latitude</span> : <div  className="px-8 w-full border-2 bg-gray-100 h-10 rounded-lg shadow-md focus:bg-blue-100 focus:ring-0 flex-row-center">{geoLocation.lat.toFixed(8)}</div> 
                    </div>
                </div>
                {/* <div className="btn btn-primary flex-row-center mx-14" onClick={saveLocation}>{state.btnSaveTxt}</div> */}
            </div>
        </div>
    )
}


const SettingsTab = () => {

    return (
    <div className='w-full flex-col-center h-96 mt-32 bg-gray-100'>
        <h2 className='my-2 text-2xl font-extrabold text-center'>Scene Settings</h2>
        {/* <MarkerControl /> */}
        <Tabs SettingsTab={GeoLocationSettings}/>
    </div>
    )
    }



export default SettingsTab
