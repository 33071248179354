
//   INFO: redux store configurations

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import combineReducers from '../Reducers';

export default function configureStore(initialState = {}) {
  return createStore(
    combineReducers,
    compose(
      applyMiddleware(thunk),
      // window.devToolsExtension ? window.devToolsExtension() : f => f
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  )
}