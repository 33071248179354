import { OrbitControls, useHelper } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';
import React, {Suspense, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/common/Loader';
import { db, storage } from 'src/Firebase';
import { setThreeObjects } from 'src/state/Actions/sceneActions';
import { hideModal, setCurrentMeshData } from 'src/state/Actions/uiActions';
import { BoxHelper } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
/* eslint-ignore line */
// import src from '../bin/image.jpg'



const ThreeModal = () => {
  const dispatch = useDispatch()
  const [imageUrl, setImageUrl] = useState("")

  const sceneReducer = useSelector((state)  =>state.sceneReducer)
  const [tab, setTab] = useState(1)
  const handlePhotoSelect = async(caption = "Model 3D", ext = "glb", url = null, setStatus) => {
    var metadata = {
      contentType: 'image/' + ext,
    };
    var name = "Model_"+ Date.now()
    var mediaRef = `scenes/${sceneReducer.id}/3d_files/${name}.glb`
    setStatus("loading")
    const blob = await fetch(url).then(url => url.blob())
    
    const object = {
      id : `obj_${Date.now()}`,
      type :`model`,
      name,
      caption,
      src : "",
      vars : [],
      mediaRef,
      position : {x : 0, y : 0, z : 0},
      rotation : {x : 0, y : 0, z : 0},
      scale : {x : 1, y : 1, z : 1},
      isVisible : true,
      actions : [],
      objectType : '3D'
    }
    await storage.ref().child(mediaRef).put(blob, metadata)
    // await 
    

    db.collection("scenes").doc(sceneReducer?.id).set({
        model3DObjects : [...sceneReducer.model3DObjects, object]
    },{merge : true})
        .then(_ => {
          dispatch(setThreeObjects([...sceneReducer.model3DObjects, object]))
        })
        .then(_ => dispatch(hideModal()))
  }

  return (
    <div className='px-12 h-96 flex flex-col'>
      <div className='flex flex-row justify-around items-center'>
        <div className={`px-5 py-3 w-full ${tab === 1 ? "bg-white blue-600 border-l-2" : "text-white bg-blue-600 rounded-br-2xl cursor-pointer"} font-extrabold text-lg`} onClick={() => setTab(1)}>Upload Model</div>
        <div className={`px-5 py-3 w-full ${tab === 2 ? "bg-white text-blue-600 border-r-2" : "text-white bg-blue-600 rounded-bl-2xl cursor-pointer"} font-extrabold text-lg`} onClick={() => setTab(2)}>Choose From Library</div>
      </div>
      {
        tab === 1 ? <UploadTab imageUrl={imageUrl} setImageUrl={setImageUrl} onClick={handlePhotoSelect} /> : <LibraryTab />
      }
    </div>
  );
};


const UploadTab = ({onClick, imageUrl, setImageUrl}) => {
//   const [imageUrl, setImageUrl] = useState("")
  const [ext, setExt] = useState("")
  const [caption, setCaption] = useState("Image")
  const [status, setStatus] = useState("upload")
  const [blob, setBlob] = useState(null)

return(
  <div className='flex flex-row justify-between border-l-2 border-r-2 border-b-2 px-3 py-7 mb-8 pt-12'>
        {status === "upload" ? 
        <div
          className='h-56 flex flex-col justify-center items-center px-5 border-2 border-dashed border-blue-600 rounded w-full'>
          <div>
            <input 
              onChange={async(e) => {
                const _caption = e.target.files[0].name.split(".")
                setExt(_caption.pop())
                setCaption(_caption.toString())
                setImageUrl(window.URL.createObjectURL(e.target.files[0]))
                setStatus("preview")
              }} 
              accept=".glb" type="file" id="three-upload-btn" hidden
            />
            <label htmlFor="three-upload-btn" className="btn-primary cursor-pointer">Upload Model</label>
          </div>
          <h4 className="text-center mt-3 font-sans font-medium">Accepted files .glb <br />Max 20MB</h4>
        </div>
        :status === "preview" ?
        <div className='flex w-full flex-col justify-between'>
            <Canvas>
                {/* <mesh>
                  <boxGeometry args={[1,1,1]} />
                  <meshBasicMaterial color="red" />
                </mesh> */}
              <ambientLight name='Default_Light' intensity={0.4} />
              <Suspense fallback={null}>
                <ThreeLoader url={imageUrl} />
              </Suspense>
                <OrbitControls enableDamping={false} />
              </Canvas>
          <div className='flex w-full justify-end'>
            <button onClick={() => onClick(caption, ext, imageUrl, setStatus)} className="btn-primary mt-4 rounded-sm ml-6">Upload</button>
          </div>
        </div>
        :
        <div className='flex-col-center h-64 items-center w-full'>
          <Loader />
          <h1>Uploading, Please wait...</h1>
        </div>
        
        }
    </div>
)
}

const LibraryTab =() => {
  const photos = [1,2,3,4,5,6,7,8,9,10,11,12,13]
  return(
    <div>
      <h2 className='text-center text-2xl font-extrabold my-3'>My Library </h2>
      <div className='flex flex-row flex-wrap justify-start h-64 overflow-y-auto' style={{width : "600px"}}>
        {
          photos.map(photo => <Box key={photo} i={photo} />)
        }
      </div>
    </div>
  )
}

const Box = ({i}) => (<div className='w-24 h-24 bg-red-600 mx-1 my-1 flex items-end justify-center text-white'>Photo {i}.jpeg</div>)



function ThreeLoader({url}) {
  const gltf = useLoader(GLTFLoader, url)
  const ref = useRef()
  // const copiedScene = useMemo(() => scene.clone(), [scene])
  return (
      <mesh
        name={"name"}
        ref={ref}
        >
        <primitive object={gltf.scene} />
      </mesh>
  )
}


export default ThreeModal;
