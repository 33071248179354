import React, { useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { useSelector } from 'react-redux';
import { findObject } from '../helpers/scaneReducerHelpers';
import MarkerSettings from './MarkerSettings';
import GeolocationControl from './GeoLocationSettings';
import ImageTrackingControl from './ImageTrackingSettings';
import SettingsTab from './SettingsTab';
import ActionsTab from './ActionsTab'

const RightControls = () => {
    const { uiReducer, sceneReducer } = useSelector((state : any) => state)
    const { current_mesh_data } = uiReducer
    const currentObject = findObject(sceneReducer, current_mesh_data.objectType, current_mesh_data.name)
    // console.log(currentObject)
    
    const [active, setActive] = useState(1);
    return (
    <div className='bg-gray-100 h-screen flex flex-col items-center px-5'>
        {
            current_mesh_data.type === "marker" ? <MarkerSettings />
            :current_mesh_data.type === "image" ?
            <ImageTrackingControl /> :
            current_mesh_data.type === "geolocation" ? <GeolocationControl /> :
            <>
                <>{current_mesh_data.name ? <>
                        <h2 className='py-3 text-xl text-center'>Properties</h2>
                        <div className='flex flex-row border-2 border-gray-50 rounded-2xl'>
                            <div className={`px-5 py-4 ${active===1 ? 'bg-blue-500 text-white' :'bg-white text-black'}
                                rounded-tl-2xl rounded-bl-2xl cursor-pointer`} onClick={()=> setActive(1)}> Appearance
                            </div>
                            {currentObject?.actions !== null && <div className={`px-5 py-4 ${active===2
                                ? 'bg-blue-500 text-white' :'bg-white text-black'} cursor-pointer`} onClick={()=>
                                setActive(2)}> Actions</div>}
                            <div className={`px-5 py-4 ${active===3 ? 'bg-blue-500 text-white' :'bg-white text-black'}
                                rounded-tr-2xl rounded-br-2xl cursor-pointer`} onClick={()=> setActive(3)}> Settings
                            </div>
                        </div>
                        <div className='w-full bg-white border-2 rounded-lg mx-12 my-7 border-gray-400 px-5 py-10'
                            style={{minHeight : '70vh'}}>
                            {
                            active === 1 ?
                            <Appearance /> :active === 2 ?
                            <ActionsTab />:
                            <SettingsTab />
                            }
                        </div>
                    </>:
                    <div className='w-full bg-gray-100 flex-col-center ' style={{minHeight : '80vh'}}>
                        <AiFillStar size={180} color='#9ca3af' />
                        <div className='w-full text-center text-2xl font-extrabold text-gray-400 my-7'>Nothing Selected
                        </div>
                        <div className='w-full text-center font-extrabold text-gray-400'>Select an object to view its
                            properties</div>
                    </div>
                    }</>
            </>
        }
        
    </div>
)
}


const Appearance = () => {
    const { uiReducer, sceneReducer } = useSelector((state : any) => state)
    const { current_mesh_data } = uiReducer
    const currentObject = findObject(sceneReducer, current_mesh_data.objectType, current_mesh_data.name)


    // console.log("Current object")
    // console.log(current_mesh_data.objectType, current_mesh_data.name,currentObject)
    
    return currentObject ? (
        <div className='flex flex-col text-gray-600 -mt-4'>
            <div>
                <h1 className='text-2xl font-extrabold text-center mb-2'>Position</h1>
                <div className='flex flex-row justify-between w-full mt-1'>
                    <span>X</span>
                    <span className='py-1 w-16 flex justify-center bg-gray-200 rounded-md'>{currentObject.position.x.toFixed(3)}</span>
                </div>
                <div className='flex flex-row justify-between w-full mt-1'>
                    <span>Y</span>
                    <span className='py-1 w-16 flex justify-center bg-gray-200 rounded-md'>{currentObject.position.y.toFixed(3)}</span>
                </div>
                <div className='flex flex-row justify-between w-full mt-1'>
                    <span>Z</span>
                    <span className='py-1 w-16 flex justify-center bg-gray-200 rounded-md'>{currentObject.position.z.toFixed(3)}</span>
                </div>
            </div>
            <div>
                <h1 className='text-2xl font-extrabold text-center mb-2'>Rotation</h1>
                <div className='flex flex-row justify-between w-full mt-1'>
                    <span>X</span>
                    <span className='py-1 w-16 flex justify-center bg-gray-200 rounded-md'>{currentObject.rotation.x.toFixed(3)}</span>
                </div>
                <div className='flex flex-row justify-between w-full mt-1'>
                    <span>Y</span>
                    <span className='py-1 w-16 flex justify-center bg-gray-200 rounded-md'>{currentObject.rotation.y.toFixed(3)}</span>
                </div>
                <div className='flex flex-row justify-between w-full mt-1'>
                    <span>Z</span>
                    <span className='py-1 w-16 flex justify-center bg-gray-200 rounded-md'>{currentObject.rotation.z.toFixed(3)}</span>
                </div>
            </div>

            <div>
                <h1 className='text-2xl font-extrabold text-center mb-2'>Scale</h1>
                <div className='flex flex-row justify-between w-full mt-1'>
                    <span>X</span>
                    <span className='py-1 w-16 flex justify-center bg-gray-200 rounded-md'>{currentObject.scale.x.toFixed(3)}</span>
                </div>
                <div className='flex flex-row justify-between w-full mt-1'>
                    <span>Y</span>
                    <span className='py-1 w-16 flex justify-center bg-gray-200 rounded-md'>{currentObject.scale.y.toFixed(3)}</span>
                </div>
                <div className='flex flex-row justify-between w-full mt-1'>
                    <span>Z</span>
                    <span className='py-1 w-16 flex justify-center bg-gray-200 rounded-md'>{currentObject.scale.z.toFixed(3)}</span>
                </div>
            </div>
        </div>
    ) : <div className='flex-col-center mt-20'> Unknown Issue </div>
}


export default RightControls
