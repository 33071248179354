import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Model from 'src/components/common/Modal2';
import { hideModal } from 'src/state/Actions/uiActions';
import LightModal from './LightModal';
import PrimitiveModal from './PrimitiveModal';
import ThreeModal from './ThreeModel';
import VideoModel from './VideoModel'
import PhotoModel from './PhotoModel';
import TextModel from './TextModel';

const Modal = (props : any) => {
    const { uiReducer} = useSelector((state : any) => state)
    const dispatch = useDispatch()
    const {show_modal, modal_content} = uiReducer
    const modalContent : React.FC  = ()=> {
        switch(modal_content){
            case "Model 3D":
                return <ThreeModal />
            case "Primitives":
                return <PrimitiveModal />
            case "Light":
                return <LightModal/>
            case "Video":
                return <VideoModel />
            case "Photo":
                return <PhotoModel />
            case "Text" : 
                return <TextModel />
            default:
                return <div className='flex-col-center h-40'>
                    <h1>
                    Under Development
                    </h1>
                    </div>
        }
    }
  return <Model 
  title={modal_content} 
  showModal={show_modal} 
  setShowModal={() => dispatch(hideModal())}
  onClick={() => dispatch(hideModal())}
>
     {modalContent(props)}
</Model> ;
};



export default Modal;
