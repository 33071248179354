
//  INFO: compaign list compoent i.e draft, active

import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import CompaignCards from './CompaignCard'
import CreateCompaignModal from './CreateCompaign'
import Footer from '../../components/common/Footer'
import Menu from '../../components/common/Menu'


const Index = () => {
  const [selcted, setSelected] = useState<number>(0)
  const [isCreated, setCreated] = useState(false)

    return (
        <div>
            <Helmet>
                <title>Comapigns</title>
            </Helmet>
            <Menu />
            <div className="min-h-screen">
              <div className="mt-32 mx-10 flex flex-row z-0 justify-between">
                  <h1 className="ml-1 heading text-4xl font-custom text-gray-900">Compaigns  </h1>
                  <DropdownFilter selcted={selcted} setSelected={setSelected} />
                  <CreateCompaignModal setCreated={setCreated} />
              </div>
              <div className="my-10 mx-10">
                  <h1 className="heading font-extrabold font-sans text-gray-700">Active Compaigns</h1>
                  <CompaignCards type="active" filter={selcted ===  0 ? null : selcted === 1 ? "month" : "year"} />
              </div>

              <div className="my-10 mx-10">
                  <h1 className="heading font-extrabold font-sans text-gray-700">Draft Compaigns</h1>
                  <CompaignCards isCreated={isCreated} type="draft" filter={selcted ===  0 ? null : selcted === 1 ? "month" : "year"} />
              </div>
            </div>

        <Footer />
        </div>
    )
}

const DropdownFilter = ({selcted, setSelected}:any) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const options = [
    "All",
    "This Month",
    "This Year"
  ]
  return(
    <div className="relative inline-block text-left">
  <div>
    <button onClick={() => setOpen(true)} type="button" className="inline-flex w-56 justify-between rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-bold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
      {options[selcted]}
      <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
      </svg>
    </button>
  </div>

  <div className={`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${!isOpen && "hidden"}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
    <div className="py-1" role="none">
      {
        options.map((option, index) => <span onClick={() =>{
          setSelected(index)
          setOpen(false)
        }} key={index} className="text-gray-700 block px-4 py-2 text-sm cursor-pointer">{option}</span>)
      }
      </div>
  </div>
</div>
  )
}


export default Index
