import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RiVideoFill } from 'react-icons/ri'
import { MdInsertPhoto, MdDelete, MdContentCopy } from 'react-icons/md'
import { FiBox } from 'react-icons/fi'
import { HiLightBulb, HiOutlineLocationMarker } from 'react-icons/hi'
import { FaAngular } from 'react-icons/fa'
import { BsImageAlt } from 'react-icons/bs'
import { IoDiamondOutline, IoArrowForwardCircle } from 'react-icons/io5'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
// import { GrDocumentImage } from 'react-icons/gr'
import { GiGearStickPattern } from 'react-icons/gi'
import { setSceneProperties } from 'src/state/Actions/sceneActions'
import { showModal, setCurrentMeshData } from 'src/state/Actions/uiActions'
import { copyObject, deleteObject, setVisibility } from './helpers/scaneReducerHelpers'

const LeftMenu = ({id}) => {
    // const [sceneType, setSceneType] = useState("marker")

    const dispatch = useDispatch()
    // const { id } = props.match.params
    const {sceneType} = useSelector(state => state.sceneReducer)

    const { type } = useSelector(state => state.uiReducer.current_mesh_data)

    const { primitiveObjects, lightObjects, photoObjects, videoObjects, textObjects,model3DObjects } = useSelector(state => state.sceneReducer)
    
    const handleDescriptor = () => {

        if(sceneType === "marker"){
            dispatch(setCurrentMeshData({
                type : "marker"
            }))
        }else if(sceneType === "image"){
            dispatch(setCurrentMeshData({
                type : "image"
            }))
        }else if(sceneType === "geolocation"){
            dispatch(setCurrentMeshData({
                type : "geolocation"
            }))
        }

    }

    let active = type === "marker" ||  type ==="geolocation" || type === "image" ? true : false //"bg-white text-black" : "bg-gray-400 hover:bg-gray-600 text-white"
return (
<div className='w-full bg-gray-100' style={{height : '90vh'}}>
    <div className=" pl-5 pr-3 " >
        <h2 className='py-5 text-xl text-center'>My AR Content</h2>
        <MenuItem Icon={MdInsertPhoto} name='Photo'id={1} items={photoObjects}  />
        <MenuItem Icon={RiVideoFill} name='Video'id={2} items={videoObjects} />
        <MenuItem Icon={FiBox} name='Model 3D'id={3} items={model3DObjects} />
        {/* <MenuItem Icon={FaAngular} name='Text'id={4} items={textObjects} /> */}
        <MenuItem Icon={IoDiamondOutline} name='Primitives'id={5} items={primitiveObjects} />
        <MenuItem Icon={HiLightBulb} name='Light'id={6} items={lightObjects} />
    </div>
    <div onClick={handleDescriptor} 
    className={`mt-5 py-2 px-4 w-full 
                ${active ? "bg-indigo-500  text-white" : "bg-white text-black hover:text-white hover:bg-indigo-500"}  
                flex flex-row items-center cursor-pointer  ml-5 border-t-2 border-b-2 border-l-2 border-gray-300 rounded-xl`}>
        {
            sceneType === "marker" ? 
        <div className='flex justify-between items-center w-full'>
            <div className='flex-row-center'>
                <GiGearStickPattern size={30} /> <span className='text-xl ml-2'>Marker</span>
            </div>
            <IoArrowForwardCircle size={30}  className='mr-4' />
            {/* color={`${active ? "bg-gray-100":"white"}`} */}
        </div>
        :sceneType === "image" ?
        <div className='flex justify-between items-center w-full'>
            <div className='flex-row-center'>
                <BsImageAlt size={30} /> <span className='text-xl ml-2'>Image Tracking</span>
            </div>
            <IoArrowForwardCircle size={30} className='mr-4' />
        </div>
        : sceneType === "geolocation" ?
        <div className='flex justify-between items-center w-full'>
            <div className='flex-row-center'>
                <HiOutlineLocationMarker size={30} /> <span className=' text-xl ml-2'>Geolocation</span>
            </div>
            <IoArrowForwardCircle size={30} className='mr-4' />
        </div>

        :null

        }
    </div>
</div>
)
}

const MenuItem = ({name='Menu',id=0,items=[], Icon}) => {
    const dispatch = useDispatch()
    const {current_mesh_data} = useSelector(state => state.uiReducer)
    const { sceneReducer } = useSelector(state => state)
    

    
return(
<div className="tab w-full overflow-hidden my-2">
    <input className="absolute opacity-0" id={`tab-single-${id}`} type="radio" name="tabs2" />
    <div className="flex flex-row justify-between bg-white items-center cursor-pointer border-2 rounded-lg py-1">
        <label className='w-full flex flex-row items-center pl-3' htmlFor={`tab-single-${id}`}>
            <Icon color="blue" size={35} />
            <span className="block p-2 leading-normal text-base font-extrabold"  >{name}</span>
            {items.length > 0 &&    
            <span className='font-extrabold text-blue-500 font-coda'>{items.length}{items.length > 1 ? " items" : " item"}</span>
            }
        </label>
        <span onClick={()=> dispatch(showModal(name))} className="text-3xl text-blue-600 font-extrabold mx-3">+</span>
    </div>
    <div className="tab-content overflow-hidden border-l-2 ml-3 mr-2 border-indigo-500 leading-normal cursor-pointer">
        {
            items.map((item, index) => <div 
                className={`${current_mesh_data.name === item.name && "bg-indigo-500 text-white"} flex flex-row justify-between items-center px-4 py-2 bg-gray-300 rounded-xl ml-4 my-1 cursor-pointer`} 
                key={index}>
            <span onClick={() => {
                    dispatch(setCurrentMeshData({
                        name : item.name,
                        objectType : item?.objectType
                    }))
                }}  className='cursor-pointer w-12'>{item.name.slice(0, 18)} </span>
            <ul className="flex flx-row">
                <li onClick={() => dispatch(setSceneProperties(setVisibility(sceneReducer, name, item.id)))} className='mx-2'>{item.isVisible ? <AiFillEye size={22} className='text-white' /> :  <AiFillEyeInvisible size={22} className='text-white' />}</li>
                <li onClick={() => {
                    dispatch(setSceneProperties(deleteObject(sceneReducer, name, item.id)))
                    dispatch(setCurrentMeshData({
                        name : "",
                        objectType : ""
                    }))
                }
                } className='mx-2'><MdDelete size={22} className='text-white ' /></li>
                <li onClick={() => dispatch(setSceneProperties(copyObject(sceneReducer, name, item.id)))} className='mx-2'><MdContentCopy size={22} className='text-white ' /></li>
            </ul>
        </div>)
        }
    </div>
</div>
);
}



export default LeftMenu