import React, { useState, useEffect, Suspense, useRef} from 'react'
// import { useAspect } from "@react-three/drei";
import { setCurrentMeshData } from 'src/state/Actions/uiActions';
import { Html, useHelper } from '@react-three/drei'
import { BoxHelper } from 'three'
import { storage } from 'src/Firebase';
// import { Wrapper } from './ImageObject'



const VideoObjects = ({state, dispatch}) => {
  const { videoObjects } = state.sceneReducer

  return (
    <Suspense fallback={null}>
    <group>
      {
        videoObjects.map((video, index) => {
        return <Wrapper key={index} object={video} dispatch={dispatch}  state={state}/>
      })
      }
    </group>
    </Suspense>
  )
}

function Video({object, dispatch, state, url}) {
  const {position, rotation, scale, /*name, id, caption, isVisible, src, type*/} = object
  const ref = useRef()

  // const size = useAspect(1080, 720,1);
  const [isPlaying, setPlaying] = useState(false)
  useHelper(ref, BoxHelper, 'red')

  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = url;
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    return vid;
  });

  const setMeshData = (name) => {
    // dispatch(setCurrentMesh(name))
    dispatch(setCurrentMeshData({
        objectType : "video",
        name
    }))
}

  // Keep in mind videos can only play once the user has interacted with the site ...
  useEffect(() => video.play(), [video]);
  return (
    <mesh onClick={() => {
      setMeshData(object.name)
      if(isPlaying){
        video.pause();
        setPlaying(false)
      }
      else {
        video.play()
        setPlaying(true)
      }
      }} 
      ref={ref}
      rotation={[rotation.x, rotation.y, rotation.z]}
      position={[position.x, position.y, position.z]}
      scale={[scale.x, scale.y, scale.z]}
      name={object.name}
      visible={object.isVisible}
      userData={{objectType : "video", id : object.id}}
    >
      <planeBufferGeometry />
      <meshBasicMaterial>
        <videoTexture attach="map" args={[video]} />
      </meshBasicMaterial>
    </mesh>
  );
}

const Wrapper = ({dispatch , object, state}) => {
  const [url, setUrl ] = useState(null)

  useEffect(() => {
    storage.ref().child(object.mediaRef)
    .getDownloadURL()
    .then(u => setUrl(u))
    .catch(_ => null)

    return () => {
      setUrl(null)
    }
  },[])

  return url ? <Video url={url} object={object} state={state} dispatch={dispatch} /> : <Loader />

}

function Loader() {
  // const { progress } = useProgress()
  return <Html center>loading...</Html>
}


export default VideoObjects