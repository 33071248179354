import React from 'react'
import Header from '../../components/common/LandingHeader'
import Footer from '../../components/common/Footer'
import Services from './Services'
import { Helmet } from 'react-helmet'


const index = () => {
    return (
        <div>
            <Helmet>
                <title>
                    Home
                </title>
            </Helmet>
            <Header />
            <Services />

            <Footer />
        </div>
    )
}

export default index
