import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSceneProperties } from 'src/state/Actions/sceneActions'
import { updateObject } from '../../helpers/scaneReducerHelpers'
import PhotoSettings from './PhotoSettings'
import PrimitiveSettings from './PrimitiveSettings'
import ThreeSettings from './ThreeSettings'
import VideoSettings from './VideoSettings'

const SettingsTab = () => {
    const { uiReducer, sceneReducer } = useSelector((state) => state)
    const { objectType, mesh } = uiReducer.current_mesh_data
    const { primitiveObjects } = sceneReducer

    const dispatch = useDispatch()
    const [data, setData] = useState([])
    // var currentObject
    const currentObject = primitiveObjects.find(obj => obj.name === mesh.name)//findObject(sceneReducer, mesh.objectType, mesh.name)
    
  return (
    <div className='flex-col-center justify-start h-96 overflow-y-auto'>
      {/* {currentObject.vars.length && <div>
        {
          currentObject.vars.map((_var, index) => <Option key={index} data={_var} i={index} onChange={onChange} />)
        }
      </div>}
      {currentObject?.name} */}
      {
        objectType === 'primitive'?
        <PrimitiveSettings />
        :objectType === 'light'?
        <div>Light Settings</div>
        :objectType === 'text'?
        <div>Text Settings</div>
        :objectType === '3D'?
        <ThreeSettings />
        :objectType === 'photo'?
        <PhotoSettings />
        :objectType === 'video'?
        <VideoSettings />
        :null
      }
        {/* <div className='btn-primary' onClick={dispatchValues}>Apply</div> */}
    </div>
  )
}


const Option = ({data, i, onChange}) => {
  const {name, value} = data 
  let val = value
  const parsedVal = name.split('-').join(' ')
  const inputStyle = `form-control block  w-full px-3 py-1 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`
  return (
    <div>
      <span className='capitalize'>{parsedVal}</span>
      {
        data?.type === 'color' ?<input type="color" className={inputStyle} value={val} onChange={e => onChange(e.target.value , i)} />  
        :data?.type === 'boolean' ? <input type="checkbox" className={`${inputStyle}`} value={val} onChange={e => onChange(e.target.value , i)} />  
        :
        <input type={'text'} className={inputStyle} value={val} onChange={e => onChange(e.target.value , i)} />     
      }
    </div>
  )
}


export default SettingsTab